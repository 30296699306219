import { createContext, ReactNode, useContext, useState } from 'react';

export enum SideBarButtonSelectionTypes {
    ChatHistorySelection = 'CHAT_HISTORY_SELECTION',
    KplListSelection = 'KPL_LIST_SELECTION',
    SettingSelection = 'SETTING_SELECTION',
}

interface ISideBarContext {
    sideBarButtonSelection: SideBarButtonSelectionTypes;
    setSideBarButtonSelection: React.Dispatch<React.SetStateAction<SideBarButtonSelectionTypes>>;
    isCollapsed: boolean;
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    showMobileSideBar: boolean;
    setShowMobileSideBar: React.Dispatch<React.SetStateAction<boolean>>;
    handleMobileMenuIconClick: Function;
    showFeedbackModal: boolean;
    setShowFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ISideBarContextProvider {
    children: ReactNode;
}

const SideBarContext = createContext<ISideBarContext>({
    sideBarButtonSelection: SideBarButtonSelectionTypes.ChatHistorySelection,
    setSideBarButtonSelection: () => {},
    isCollapsed: false,
    setIsCollapsed: () => {},
    showMobileSideBar: false,
    setShowMobileSideBar: () => {},
    handleMobileMenuIconClick: () => {},
    showFeedbackModal: false,
    setShowFeedbackModal: () => {},
});

export const SideBarContextProvider: React.FC<ISideBarContextProvider> = ({ children }) => {
    const [sideBarButtonSelection, setSideBarButtonSelection] = useState<SideBarButtonSelectionTypes>(
        SideBarButtonSelectionTypes.ChatHistorySelection
    );
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [showMobileSideBar, setShowMobileSideBar] = useState<boolean>(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);

    const handleMobileMenuIconClick = () => {
        setShowMobileSideBar(!showMobileSideBar);
        setIsCollapsed(showMobileSideBar ? false : isCollapsed);
    };

    return (
        <SideBarContext.Provider
            value={{
                sideBarButtonSelection,
                setSideBarButtonSelection,
                isCollapsed,
                setIsCollapsed,
                showMobileSideBar,
                setShowMobileSideBar,
                handleMobileMenuIconClick,
                showFeedbackModal,
                setShowFeedbackModal,
            }}
        >
            {children}
        </SideBarContext.Provider>
    );
};

export const useSideBarContext = (): ISideBarContext => {
    const context = useContext(SideBarContext);

    if (!context) {
        throw new Error('useChatContext must be used within a ChatContextProvider');
    }

    return context;
};
