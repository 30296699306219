import { useSideBarContext } from '../../../contexts';

type MobileMenuButtonProps = {
    className?: string;
};

const MobileMenuButton = ({ className = '' }: MobileMenuButtonProps) => {
    const { showMobileSideBar, handleMobileMenuIconClick } = useSideBarContext();

    return (
        <i
            className={`px-2 cursor-pointer text-h5 text-N-600 hidden sm:inline-block ${showMobileSideBar ? `ri-close-fill` : `ri-menu-fill`} ${className} `}
            onClick={() => handleMobileMenuIconClick()}
        />
    );
};

export { MobileMenuButton };
