import React from 'react';
import { SideBarButtonSelectionTypes, useSideBarContext } from '../../../contexts';
import { CN } from '../../../utils';
import { KPLList } from '../kpl-list';
import { Settings } from '../settings';
import { ChatHistory } from '../chat-history';

export const ExtendedSidebar = () => {
    const { sideBarButtonSelection, isCollapsed } = useSideBarContext();

    return (
        <React.Fragment>
            <div
                className={CN(
                    'extended_sidebar h-full bg-white border-r border-r-N-200 max-w-[350px] sm:max-w-full transition-all',
                    {
                        'w-full duration-700 sm:absolute sm:left-[68px] sm:z-[9] sm:w-[calc(100vw-68px)]': !isCollapsed,
                        'w-0 duration-700 hidden': isCollapsed,
                    }
                )}
            >
                {sideBarButtonSelection === SideBarButtonSelectionTypes.ChatHistorySelection && <ChatHistory />}
                {sideBarButtonSelection === SideBarButtonSelectionTypes.KplListSelection && <KPLList />}
                {sideBarButtonSelection === SideBarButtonSelectionTypes.SettingSelection && <Settings />}
            </div>
        </React.Fragment>
    );
};
