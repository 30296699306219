export type ExtractedContent = {
    text: string;
    isCode: boolean;
    language: string;
};

export function extractContentAndCodeBlocks(markdownText: string): ExtractedContent[] {
    const codeBlockRegex = /```([\s\S]*?)```/g;
    const codeBlockPlaceholders: string[] = [];
    const codeBlocks: ExtractedContent[] = [];
    let remainingText = markdownText;

    // Extract code blocks and replace them with placeholders
    let match;
    while ((match = codeBlockRegex.exec(markdownText)) !== null) {
        const codeBlockText = match[1];
        const languageMatch = match[0].match(/```(\S+)/);
        const language = languageMatch ? languageMatch[1] : '';
        codeBlocks.push({ text: codeBlockText.replace(language, ''), isCode: true, language });
        codeBlockPlaceholders.push(`CODE_BLOCK_${codeBlocks.length - 1}`);
        remainingText = remainingText.replace(match[0], codeBlockPlaceholders[codeBlocks.length - 1]);
    }

    // Split the remaining text using placeholders and create the final result
    const remainingTextParts = remainingText.split(/CODE_BLOCK_\d+/);
    const result: ExtractedContent[] = [];
    let codeBlockIndex = 0;

    for (const part of remainingTextParts) {
        if (part.trim() !== '') {
            result.push({ text: part, isCode: false, language: '' });
        }

        if (codeBlockIndex < codeBlocks.length) {
            result.push(codeBlocks[codeBlockIndex]);
            codeBlockIndex++;
        }
    }

    // Replace escaped newlines in text
    return result.map(content => ({ ...content, text: content.text.replace(/\\n/g, '\n').trim() }));
}
