import { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { CSS_SCROLL } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { CN } from '../../../utils';

const YouTubeEmbed = ({ url }: { url: string }) => {
    const { fullscreen } = useChatContext();
    const videoId = url.split('v=')[1];

    return (
        <div
            className={CN('relative overflow-hidden pb-[56.25%]', {
                'min-w-[480px] min-h-[360px] sm:min-w-max': fullscreen,
            })}
        >
            <iframe
                className="py-2 absolute inset-0 w-full h-full"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </div>
    );
};

const linkRender = ({ href, children }: any) => {
    // Check if the link is a YouTube video link
    if (href?.startsWith('https://www.youtube.com/watch')) {
        return <YouTubeEmbed url={href} />;
    }

    // Default behavior for other links
    return (
        <a target="_blank" href={href}>
            {children}
        </a>
    );
};

const Markdown = ({ markdownText }: { markdownText: string }) => {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw as any]}
            className={CN('prose prose-stone prose-sm dark:prose-invert markdown break-words !text-white', CSS_SCROLL)}
            linkTarget="_blank"
            components={{
                a: linkRender,
                link: linkRender,
            }}
            children={markdownText}
        />
    );
};

export const MemoizedMarkdown = memo(Markdown);
