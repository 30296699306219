import { Avatar } from '@kaya/kaya-ui-design-system-pb';
import { AppConfigType } from '../../../contexts';
import { CN } from '../../../utils';
import { ChatType, FeatureType } from '../../../__generated__/graphql';

interface KPLCardProps {
    actionKpl: boolean;
    currentFeatureType: FeatureType;
    feature: AppConfigType;
    onClick: () => void;
}

export const KPLCard = ({ feature, currentFeatureType, actionKpl, onClick }: KPLCardProps) => {
    const icon = feature.icon;
    const name = feature.title;
    const description = feature.description;
    const selected = feature.configKey === currentFeatureType;

    return (
        <div
            onClick={onClick}
            className={CN(
                'flex flex-start gap-x-3 px-4 py-3 border border-N-200 cursor-pointer hover:bg-B-50 hover:border hover:border-B-600',
                {
                    'bg-B-50 border border-B-600': selected,
                    '!bg-N-100': actionKpl,
                }
            )}
        >
            <div>
                <Avatar type="icon" shape="square" size="md" icon={CN(icon, 'text-sm')} />
            </div>
            <div className="flex flex-col">
                <p className="text-sm sm:text-sm font-500 text-N-900">{name}</p>
                <p className="text-xs sm:text-sm font-500 text-N-600">{description}</p>
            </div>
        </div>
    );
};
