import { KeycloakService } from '../services';
import Chatbot from './Chatbot';

const ChatbotApp = () => {
    return (
        <>
            {KeycloakService.keycloak.tokenParsed && (
                <Chatbot
                    popup={true}
                    showHeader
                    keycloakClient={KeycloakService.keycloak}
                    userDetails={{
                        id: KeycloakService.keycloak.tokenParsed?.sub || '',
                        imageUrl: 'https://img.icons8.com/ios-filled/50/737373/user-male-circle.png',
                        name: KeycloakService.keycloak.tokenParsed?.name || '',
                        sessionId: KeycloakService.keycloak.tokenParsed?.sid || '',
                        email: KeycloakService.keycloak.tokenParsed?.email || '',
                    }}
                />
            )}
            <button className="m-1" onClick={() => KeycloakService.doLogout({})}>
                <i className="ri-logout-circle-r-line"></i> Logout
            </button>
        </>
    );
};

export default ChatbotApp;
