/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type ActionOnDislikedMessageInput = {
  messageId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  problems?: Maybe<Array<Maybe<Problem>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ActivityLogMessage = {
  __typename?: 'ActivityLogMessage';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isUser?: Maybe<Scalars['Boolean']['output']>;
  showApprove?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<ActivityLogMessageType>;
};

export enum ActivityLogMessageType {
  Chat = 'CHAT',
  Init = 'INIT'
}

export type AddActivityLogInput = {
  date: Scalars['DateTime']['input'];
  updatedWorklog?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  worklog?: InputMaybe<Scalars['String']['input']>;
};

export type AddApproveLogInput = {
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  worklog?: InputMaybe<Scalars['String']['input']>;
};

export type AddCommonMessageInput = {
  content: Scalars['String']['input'];
  type: CommonMessageType;
  userId: Scalars['ID']['input'];
};

export type AddFeedbackInput = {
  feedback: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  userName: Scalars['String']['input'];
};

export type AddMessageFeedbackInput = {
  feedback: Scalars['Boolean']['input'];
  messageId: Scalars['ID']['input'];
};

export type AddMessageInput = {
  author: MessageAuthor;
  content: Scalars['String']['input'];
  conversationId?: InputMaybe<Scalars['ID']['input']>;
  replyTo?: InputMaybe<Scalars['ID']['input']>;
  sessionId: Scalars['ID']['input'];
  settings?: InputMaybe<MessageSettingsInput>;
  type: MessageType;
  userId: Scalars['ID']['input'];
};

export type AddSmeMessageInput = {
  answer: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  responseMessageId: Scalars['ID']['input'];
  smeUserId: Scalars['ID']['input'];
};

export type AddTimesheetInput = {
  feature: FeatureType;
  file: Scalars['Upload']['input'];
  fullname: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type AiaCommonMessage = {
  __typename?: 'AiaCommonMessage';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type AiaMessageSmeResponse = {
  __typename?: 'AiaMessageSmeResponse';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  smeUser?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnalyzeCallTranscriptInput = {
  transcript: Scalars['Upload']['input'];
};

export type AnalyzeTestCasesInput = {
  testCases: Scalars['Upload']['input'];
};

export type ApplicationConfig = {
  __typename?: 'ApplicationConfig';
  chatType: ChatType;
  configKey: FeatureType;
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  metadata: Scalars['JSONObject']['output'];
  title: Scalars['String']['output'];
};

export type ApplicationUserConfig = {
  __typename?: 'ApplicationUserConfig';
  email: Scalars['String']['output'];
  features: Scalars['String']['output'];
  metadata: Scalars['JSONObject']['output'];
  models: Scalars['String']['output'];
  override: Scalars['Boolean']['output'];
};

export type CallReportOptions = {
  __typename?: 'CallReportOptions';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  option?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
};

export enum ChatType {
  AddTimesheet = 'ADD_TIMESHEET',
  Assistant = 'ASSISTANT',
  ChatWithDocuments = 'CHAT_WITH_DOCUMENTS',
  Export = 'EXPORT',
  Feedback = 'FEEDBACK',
  GenerateTechnicalSpec = 'GENERATE_TECHNICAL_SPEC',
  LiveCustomerAgent = 'LIVE_CUSTOMER_AGENT',
  SprintAnalysisReport = 'SPRINT_ANALYSIS_REPORT',
  Timesheet = 'TIMESHEET'
}

export enum CommonMessageType {
  LearningMaterial = 'LEARNING_MATERIAL',
  SmeResponse = 'SME_RESPONSE'
}

export type Conversation = {
  __typename?: 'Conversation';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
  threadType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ConversationResult = {
  __typename?: 'ConversationResult';
  feature: ApplicationConfig;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DeleteDocumentInput = {
  conversationId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type DeleteIndexInput = {
  conversationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum DocumentPersistenceType {
  Permanent = 'PERMANENT',
  Temporary = 'TEMPORARY'
}

export enum FeatureType {
  AddTimesheet = 'ADD_TIMESHEET',
  ChatWithDocuments = 'CHAT_WITH_DOCUMENTS',
  ClientKbAffinity = 'CLIENT_KB_AFFINITY',
  ClientKbAtairos = 'CLIENT_KB_ATAIROS',
  ClientKbBiotech = 'CLIENT_KB_BIOTECH',
  ClientKbBiotechInvestments = 'CLIENT_KB_BIOTECH_INVESTMENTS',
  ClientKbCiti = 'CLIENT_KB_CITI',
  ClientKbCitiInsights = 'CLIENT_KB_CITI_INSIGHTS',
  ClientKbComcast = 'CLIENT_KB_COMCAST',
  ClientKbCove = 'CLIENT_KB_COVE',
  ClientKbCryptoTwitter = 'CLIENT_KB_CRYPTO_TWITTER',
  ClientKbFinancial = 'CLIENT_KB_FINANCIAL',
  ClientKbHealthcare = 'CLIENT_KB_HEALTHCARE',
  ClientKbHealthfirstSupport = 'CLIENT_KB_HEALTHFIRST_SUPPORT',
  ClientKbInsurance = 'CLIENT_KB_INSURANCE',
  ClientKbInvestmentBanking = 'CLIENT_KB_INVESTMENT_BANKING',
  ClientKbInKind = 'CLIENT_KB_IN_KIND',
  ClientKbJpmcEmployerRetention = 'CLIENT_KB_JPMC_EMPLOYER_RETENTION',
  ClientKbJpmcOnboarding = 'CLIENT_KB_JPMC_ONBOARDING',
  ClientKbJpmcSecFillings = 'CLIENT_KB_JPMC_SEC_FILLINGS',
  ClientKbPerceptive = 'CLIENT_KB_PERCEPTIVE',
  ClientKbRegulus = 'CLIENT_KB_REGULUS',
  ClientKbSantander = 'CLIENT_KB_SANTANDER',
  ClientKbSonicAutomotiveInternal = 'CLIENT_KB_SONIC_AUTOMOTIVE_INTERNAL',
  ClientKbSonicBmw = 'CLIENT_KB_SONIC_BMW',
  ClientKbSonicEcho = 'CLIENT_KB_SONIC_ECHO',
  ClientKbSonicFinancial = 'CLIENT_KB_SONIC_FINANCIAL',
  ClientKbSports = 'CLIENT_KB_SPORTS',
  ClientKbSportsUnlimited = 'CLIENT_KB_SPORTS_UNLIMITED',
  ClientKbSystemHealth = 'CLIENT_KB_SYSTEM_HEALTH',
  ClientKbTechFinancial = 'CLIENT_KB_TECH_FINANCIAL',
  ClientKbWealthManagement = 'CLIENT_KB_WEALTH_MANAGEMENT',
  Export = 'EXPORT',
  Feedback = 'FEEDBACK',
  General = 'GENERAL',
  GenerateTechnicalSpec = 'GENERATE_TECHNICAL_SPEC',
  KayaFlexForce = 'KAYA_FLEX_FORCE',
  KayaKbDev = 'KAYA_KB_DEV',
  KayaKbRecruitment = 'KAYA_KB_RECRUITMENT',
  KayaKbSales = 'KAYA_KB_SALES',
  KayaKbSqlAgent = 'KAYA_KB_SQL_AGENT',
  SprintAnalysisReport = 'SPRINT_ANALYSIS_REPORT',
  TestCaseAnalysis = 'TEST_CASE_ANALYSIS',
  Timesheet = 'TIMESHEET',
  HypertensionDrugs = 'CLIENT_KB_HYPERTENSION_DRUGS'
}

export type FollowUpQuestion = {
  __typename?: 'FollowUpQuestion';
  answer?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['ID']['output']>;
  question?: Maybe<Scalars['String']['output']>;
};

export type GenerateCodeFromRequirementsInput = {
  architecture: Scalars['String']['input'];
  authentication: Scalars['String']['input'];
  database: Scalars['String']['input'];
  deployment: Scalars['String']['input'];
  guidelines?: InputMaybe<Scalars['Upload']['input']>;
  language: Scalars['String']['input'];
  libraries: Scalars['String']['input'];
  requirements: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GenerateJiraTicketsInput = {
  jiraInstanceName?: InputMaybe<Scalars['String']['input']>;
  jiraProjectKey?: InputMaybe<Scalars['String']['input']>;
  jiraUserEmail?: InputMaybe<Scalars['String']['input']>;
  jiraUserToken?: InputMaybe<Scalars['String']['input']>;
  requirements: Scalars['String']['input'];
  type?: InputMaybe<RequirementType>;
  userId: Scalars['ID']['input'];
};

export type GenerateRequirementsInput = {
  requirements?: InputMaybe<Scalars['String']['input']>;
  transcript?: InputMaybe<Scalars['Upload']['input']>;
  type?: InputMaybe<RequirementType>;
  userId: Scalars['ID']['input'];
};

export type GenerateSprintAnalysisReportInput = {
  jiraInstanceName?: InputMaybe<Scalars['String']['input']>;
  jiraProjectKey?: InputMaybe<Scalars['String']['input']>;
  jiraUserEmail?: InputMaybe<Scalars['String']['input']>;
  jiraUserToken?: InputMaybe<Scalars['String']['input']>;
  project: Scalars['String']['input'];
  sprint: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GetActivityLogsInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type GetConversationHistoryInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  searchKey: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GetFollowUpQuestionsInput = {
  messageId: Scalars['ID']['input'];
};

export type GetJiraSettingsInput = {
  jiraInstanceName?: InputMaybe<Scalars['String']['input']>;
  jiraProjectKey?: InputMaybe<Scalars['String']['input']>;
  jiraUserEmail?: InputMaybe<Scalars['String']['input']>;
  jiraUserToken?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetKplGuidelinesInput = {
  configKey: FeatureType;
};

export type GetMessageInput = {
  conversationId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type GetTechnicalQuestionsInput = {
  messageId: Scalars['ID']['input'];
};

export type GetTimesheetsInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type GroupedConversationResult = {
  __typename?: 'GroupedConversationResult';
  conversations: Array<ConversationResult>;
  date: Scalars['String']['output'];
};

export type JiraProjects = {
  __typename?: 'JiraProjects';
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sprints?: Maybe<Array<Maybe<JiraSprints>>>;
};

export type JiraSprints = {
  __typename?: 'JiraSprints';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  originBoardId: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type KplGuideline = {
  __typename?: 'KplGuideline';
  guidelinesText: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  kplConfigKey: FeatureType;
};

export type LiveCustomerAgentDetails = {
  __typename?: 'LiveCustomerAgentDetails';
  audioFileUrl: Scalars['String']['output'];
  transcript: Array<LiveCustomerAgentTranscript>;
};

export type LiveCustomerAgentTranscript = {
  __typename?: 'LiveCustomerAgentTranscript';
  message: Scalars['String']['output'];
  time: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type MarkMessageAsReadInput = {
  messageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Message = {
  __typename?: 'Message';
  author?: Maybe<MessageAuthor>;
  content?: Maybe<Scalars['String']['output']>;
  conversationId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  feedback?: Maybe<Scalars['Boolean']['output']>;
  followupQuestions?: Maybe<Array<Maybe<FollowUpQuestion>>>;
  id?: Maybe<Scalars['ID']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  processing?: Maybe<Scalars['Boolean']['output']>;
  replyTo?: Maybe<Scalars['ID']['output']>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  sources?: Maybe<Array<Maybe<Source>>>;
  type?: Maybe<MessageType>;
};

export enum MessageAuthor {
  System = 'SYSTEM',
  User = 'USER'
}

export type MessageSettingsInput = {
  feature: Scalars['String']['input'];
  hasTemporaryFiles: Scalars['Boolean']['input'];
  model: Scalars['String']['input'];
  options: MessageSettingsOptionsInput;
  type: Scalars['String']['input'];
};

export type MessageSettingsOptionsInput = {
  dynamicPrompts?: InputMaybe<Scalars['Boolean']['input']>;
  noLLM?: InputMaybe<Scalars['Boolean']['input']>;
  webSearch?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MessageSubscriptionInput = {
  conversationId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum MessageType {
  CallReportsInput = 'CALL_REPORTS_INPUT',
  CallTranscriptInput = 'CALL_TRANSCRIPT_INPUT',
  DocumentInput = 'DOCUMENT_INPUT',
  Image = 'IMAGE',
  LearningMaterial = 'LEARNING_MATERIAL',
  SmeResponse = 'SME_RESPONSE',
  TestCasesInput = 'TEST_CASES_INPUT',
  Text = 'TEXT',
  TimesheetInput = 'TIMESHEET_INPUT'
}

export type Mutation = {
  __typename?: 'Mutation';
  actionOnDislikedMessage?: Maybe<Scalars['String']['output']>;
  addActivityLog?: Maybe<ActivityLogMessage>;
  addCommonMessage?: Maybe<AiaCommonMessage>;
  addFeedback?: Maybe<Scalars['String']['output']>;
  addMessage?: Maybe<Message>;
  addMessageFeedback?: Maybe<Message>;
  addSMEMessage?: Maybe<AiaMessageSmeResponse>;
  analyzeCallTranscript?: Maybe<Scalars['String']['output']>;
  analyzeTestCases?: Maybe<Scalars['String']['output']>;
  approveActivityLog?: Maybe<ActivityLog>;
  clearCache?: Maybe<Scalars['String']['output']>;
  deleteDocument?: Maybe<Scalars['String']['output']>;
  deleteFaissIndex?: Maybe<Scalars['String']['output']>;
  generateCodeFromRequirements?: Maybe<Scalars['String']['output']>;
  generateJiraTickets?: Maybe<Scalars['String']['output']>;
  generateRequirements?: Maybe<Scalars['String']['output']>;
  generateSprintAnalysisReport?: Maybe<SprintAnalysisReport>;
  markMessagesAsRead?: Maybe<Array<Maybe<AiaCommonMessage>>>;
  saveNewCallReports?: Maybe<Array<Maybe<CallReportOptions>>>;
  saveNewDocuments?: Maybe<Scalars['String']['output']>;
  stopGeneration?: Maybe<Scalars['String']['output']>;
  updateConversation?: Maybe<Conversation>;
  uploadTimesheet?: Maybe<Scalars['String']['output']>;
  upsertParticipant?: Maybe<User>;
};


export type MutationActionOnDislikedMessageArgs = {
  input: ActionOnDislikedMessageInput;
};


export type MutationAddActivityLogArgs = {
  input: AddActivityLogInput;
};


export type MutationAddCommonMessageArgs = {
  input: AddCommonMessageInput;
};


export type MutationAddFeedbackArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  input?: InputMaybe<AddFeedbackInput>;
};


export type MutationAddMessageArgs = {
  input: AddMessageInput;
};


export type MutationAddMessageFeedbackArgs = {
  input: AddMessageFeedbackInput;
};


export type MutationAddSmeMessageArgs = {
  input: AddSmeMessageInput;
};


export type MutationAnalyzeCallTranscriptArgs = {
  input: AnalyzeCallTranscriptInput;
};


export type MutationAnalyzeTestCasesArgs = {
  input: AnalyzeTestCasesInput;
};


export type MutationApproveActivityLogArgs = {
  input: AddApproveLogInput;
};


export type MutationDeleteDocumentArgs = {
  input: DeleteDocumentInput;
};


export type MutationDeleteFaissIndexArgs = {
  input: DeleteIndexInput;
};


export type MutationGenerateCodeFromRequirementsArgs = {
  input: GenerateCodeFromRequirementsInput;
};


export type MutationGenerateJiraTicketsArgs = {
  input: GenerateJiraTicketsInput;
};


export type MutationGenerateRequirementsArgs = {
  input?: InputMaybe<GenerateRequirementsInput>;
};


export type MutationGenerateSprintAnalysisReportArgs = {
  input: GenerateSprintAnalysisReportInput;
};


export type MutationMarkMessagesAsReadArgs = {
  input: MarkMessageAsReadInput;
};


export type MutationSaveNewCallReportsArgs = {
  input: SaveNewDocumentsInput;
};


export type MutationSaveNewDocumentsArgs = {
  input: SaveNewDocumentsInput;
};


export type MutationStopGenerationArgs = {
  input: StopGenerationInput;
};


export type MutationUpdateConversationArgs = {
  input: UpdateConversationInput;
};


export type MutationUploadTimesheetArgs = {
  input: AddTimesheetInput;
};


export type MutationUpsertParticipantArgs = {
  input: UpsertUserInput;
};

export type PaginatedGroupedConversationResult = {
  __typename?: 'PaginatedGroupedConversationResult';
  conversations: Array<GroupedConversationResult>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Problem = {
  __typename?: 'Problem';
  id?: Maybe<Scalars['ID']['output']>;
  problem?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getActivityLogs?: Maybe<Array<Maybe<ActivityLog>>>;
  getApplicationConfig?: Maybe<Array<Maybe<ApplicationConfig>>>;
  getApplicationUserConfig?: Maybe<Array<Maybe<ApplicationUserConfig>>>;
  getConversationHistory?: Maybe<PaginatedGroupedConversationResult>;
  getFollowUpQuestions?: Maybe<Array<Maybe<FollowUpQuestion>>>;
  getJiraSettings?: Maybe<Array<Maybe<JiraProjects>>>;
  getKplGuidelines?: Maybe<Array<Maybe<KplGuideline>>>;
  getLiveCustomerAgentDetails?: Maybe<LiveCustomerAgentDetails>;
  getMessages?: Maybe<Array<Maybe<Message>>>;
  getParticipant?: Maybe<User>;
  getParticipantByEmail?: Maybe<User>;
  getSMEMessagesResponses?: Maybe<Array<Maybe<Message>>>;
  getSourceChunks?: Maybe<Scalars['String']['output']>;
};


export type QueryGetActivityLogsArgs = {
  input: GetActivityLogsInput;
};


export type QueryGetConversationHistoryArgs = {
  input: GetConversationHistoryInput;
};


export type QueryGetFollowUpQuestionsArgs = {
  input: GetFollowUpQuestionsInput;
};


export type QueryGetJiraSettingsArgs = {
  input: GetJiraSettingsInput;
};


export type QueryGetKplGuidelinesArgs = {
  input: GetKplGuidelinesInput;
};


export type QueryGetMessagesArgs = {
  input: GetMessageInput;
};


export type QueryGetParticipantArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetParticipantByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetSourceChunksArgs = {
  sourceId: Scalars['ID']['input'];
};

export enum RequirementType {
  Business = 'BUSINESS',
  Technical = 'TECHNICAL'
}

export type SaveNewDocumentsInput = {
  conversationId: Scalars['ID']['input'];
  feature?: InputMaybe<FeatureType>;
  files: Array<Scalars['Upload']['input']>;
  type?: InputMaybe<DocumentPersistenceType>;
  userId: Scalars['ID']['input'];
};

export type Session = {
  __typename?: 'Session';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum SettingsModelType {
  Claude = 'CLAUDE',
  GoogleAi = 'GOOGLE_AI',
  Mistral = 'MISTRAL',
  OpenAi = 'OPEN_AI',
  OpenAiOmni = 'OPEN_AI_OMNI'
}

export enum SettingsOptionType {
  DynamicPrompts = 'DYNAMIC_PROMPTS',
  NoLlm = 'NO_LLM',
  WebSearch = 'WEB_SEARCH'
}

export type Source = {
  __typename?: 'Source';
  domainUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sources?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SprintAnalysisReport = {
  __typename?: 'SprintAnalysisReport';
  overview?: Maybe<Scalars['String']['output']>;
  report?: Maybe<Scalars['String']['output']>;
};

export type StopGenerationInput = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newMessage?: Maybe<Array<Maybe<Message>>>;
  newWorklog?: Maybe<Array<Maybe<ActivityLogMessage>>>;
};


export type SubscriptionNewMessageArgs = {
  input: MessageSubscriptionInput;
};


export type SubscriptionNewWorklogArgs = {
  input: WorklogSubscriptionInput;
};

export type Task = {
  __typename?: 'Task';
  duration?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  task?: Maybe<Scalars['String']['output']>;
  technologies?: Maybe<Array<Maybe<TaskTechnology>>>;
};

export type TaskTechnology = {
  __typename?: 'TaskTechnology';
  id?: Maybe<Scalars['ID']['output']>;
  technology?: Maybe<Scalars['String']['output']>;
};

export type UpdateConversationInput = {
  conversationId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type UpsertUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sessions?: Maybe<Array<Maybe<Session>>>;
};

export type WorklogSubscriptionInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type AddMessageMutationVariables = Exact<{
  input: AddMessageInput;
}>;


export type AddMessageMutation = { __typename?: 'Mutation', addMessage?: { __typename?: 'Message', id?: string | null } | null };

export type UpsertParticipantMutationVariables = Exact<{
  input: UpsertUserInput;
}>;


export type UpsertParticipantMutation = { __typename?: 'Mutation', upsertParticipant?: { __typename?: 'User', id?: string | null } | null };

export type AddActivityLogMutationVariables = Exact<{
  input: AddActivityLogInput;
}>;


export type AddActivityLogMutation = { __typename?: 'Mutation', addActivityLog?: { __typename?: 'ActivityLogMessage', id?: string | null } | null };

export type ApproveActivityLogMutationVariables = Exact<{
  input: AddApproveLogInput;
}>;


export type ApproveActivityLogMutation = { __typename?: 'Mutation', approveActivityLog?: { __typename?: 'ActivityLog', id?: string | null } | null };

export type AddFeedbackMutationVariables = Exact<{
  input?: InputMaybe<AddFeedbackInput>;
  file?: InputMaybe<Scalars['Upload']['input']>;
}>;


export type AddFeedbackMutation = { __typename?: 'Mutation', addFeedback?: string | null };

export type AddMessageFeedbackMutationVariables = Exact<{
  input: AddMessageFeedbackInput;
}>;


export type AddMessageFeedbackMutation = { __typename?: 'Mutation', addMessageFeedback?: { __typename?: 'Message', id?: string | null } | null };

export type MarkMessagesAsReadMutationVariables = Exact<{
  input: MarkMessageAsReadInput;
}>;


export type MarkMessagesAsReadMutation = { __typename?: 'Mutation', markMessagesAsRead?: Array<{ __typename?: 'AiaCommonMessage', id?: string | null } | null> | null };

export type UpdateConversationMutationVariables = Exact<{
  input: UpdateConversationInput;
}>;


export type UpdateConversationMutation = { __typename?: 'Mutation', updateConversation?: { __typename?: 'Conversation', id?: string | null } | null };

export type ActionOnDislikedMessageMutationVariables = Exact<{
  input: ActionOnDislikedMessageInput;
}>;


export type ActionOnDislikedMessageMutation = { __typename?: 'Mutation', actionOnDislikedMessage?: string | null };

export type GenerateRequirementsMutationVariables = Exact<{
  input?: InputMaybe<GenerateRequirementsInput>;
}>;


export type GenerateRequirementsMutation = { __typename?: 'Mutation', generateRequirements?: string | null };

export type GenerateJiraTicketsMutationVariables = Exact<{
  input: GenerateJiraTicketsInput;
}>;


export type GenerateJiraTicketsMutation = { __typename?: 'Mutation', generateJiraTickets?: string | null };

export type GenerateCodeFromRequirementsMutationVariables = Exact<{
  input: GenerateCodeFromRequirementsInput;
}>;


export type GenerateCodeFromRequirementsMutation = { __typename?: 'Mutation', generateCodeFromRequirements?: string | null };

export type StopGenerationMutationVariables = Exact<{
  input: StopGenerationInput;
}>;


export type StopGenerationMutation = { __typename?: 'Mutation', stopGeneration?: string | null };

export type SaveNewDocumentsMutationVariables = Exact<{
  input: SaveNewDocumentsInput;
}>;


export type SaveNewDocumentsMutation = { __typename?: 'Mutation', saveNewDocuments?: string | null };

export type DeleteDocumentMutationVariables = Exact<{
  input: DeleteDocumentInput;
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument?: string | null };

export type DeleteFaissIndexMutationVariables = Exact<{
  input: DeleteIndexInput;
}>;


export type DeleteFaissIndexMutation = { __typename?: 'Mutation', deleteFaissIndex?: string | null };

export type GenerateSprintAnalysisReportMutationVariables = Exact<{
  input: GenerateSprintAnalysisReportInput;
}>;


export type GenerateSprintAnalysisReportMutation = { __typename?: 'Mutation', generateSprintAnalysisReport?: { __typename?: 'SprintAnalysisReport', overview?: string | null, report?: string | null } | null };

export type UploadTimesheetMutationVariables = Exact<{
  input: AddTimesheetInput;
}>;


export type UploadTimesheetMutation = { __typename?: 'Mutation', uploadTimesheet?: string | null };

export type SaveNewCallReportsMutationVariables = Exact<{
  input: SaveNewDocumentsInput;
}>;


export type SaveNewCallReportsMutation = { __typename?: 'Mutation', saveNewCallReports?: Array<{ __typename?: 'CallReportOptions', option?: string | null, question?: string | null, enabled?: boolean | null } | null> | null };

export type AnalyzeCallTranscriptMutationVariables = Exact<{
  input: AnalyzeCallTranscriptInput;
}>;


export type AnalyzeCallTranscriptMutation = { __typename?: 'Mutation', analyzeCallTranscript?: string | null };

export type AnalyzeTestCasesMutationVariables = Exact<{
  input: AnalyzeTestCasesInput;
}>;


export type AnalyzeTestCasesMutation = { __typename?: 'Mutation', analyzeTestCases?: string | null };

export type GetMessagesQueryVariables = Exact<{
  input: GetMessageInput;
}>;


export type GetMessagesQuery = { __typename?: 'Query', getMessages?: Array<{ __typename?: 'Message', id?: string | null, replyTo?: string | null, content?: string | null, author?: MessageAuthor | null } | null> | null };

export type GetParticipantQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetParticipantQuery = { __typename?: 'Query', getParticipant?: { __typename?: 'User', conversations?: Array<{ __typename?: 'Conversation', id?: string | null, title?: string | null, updatedAt?: any | null, threadId?: string | null, threadType?: string | null } | null> | null } | null };

export type GetSourceChunksQueryVariables = Exact<{
  sourceId: Scalars['ID']['input'];
}>;


export type GetSourceChunksQuery = { __typename?: 'Query', getSourceChunks?: string | null };

export type GetApplicationConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApplicationConfigQuery = { __typename?: 'Query', getApplicationConfig?: Array<{ __typename?: 'ApplicationConfig', configKey: FeatureType, chatType: ChatType, description: string, icon: string, id: number, enabled: boolean, metadata: any, title: string } | null> | null };

export type GetApplicationUserConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApplicationUserConfigQuery = { __typename?: 'Query', getApplicationUserConfig?: Array<{ __typename?: 'ApplicationUserConfig', email: string, features: string, metadata: any, models: string, override: boolean } | null> | null };

export type GetKplGuidelinesQueryVariables = Exact<{
  input: GetKplGuidelinesInput;
}>;


export type GetKplGuidelinesQuery = { __typename?: 'Query', getKplGuidelines?: Array<{ __typename?: 'KplGuideline', guidelinesText: string } | null> | null };

export type GetJiraSettingsQueryVariables = Exact<{
  input: GetJiraSettingsInput;
}>;


export type GetJiraSettingsQuery = { __typename?: 'Query', getJiraSettings?: Array<{ __typename?: 'JiraProjects', id: string, key: string, name: string, sprints?: Array<{ __typename?: 'JiraSprints', id: string, name: string, originBoardId: string, state: string } | null> | null } | null> | null };

export type GetLiveCustomerAgentDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLiveCustomerAgentDetailsQuery = { __typename?: 'Query', getLiveCustomerAgentDetails?: { __typename?: 'LiveCustomerAgentDetails', audioFileUrl: string, transcript: Array<{ __typename?: 'LiveCustomerAgentTranscript', message: string, time: number, type: string }> } | null };

export type NewMessageSubscriptionVariables = Exact<{
  input: MessageSubscriptionInput;
}>;


export type NewMessageSubscription = { __typename?: 'Subscription', newMessage?: Array<{ __typename?: 'Message', id?: string | null, content?: string | null, author?: MessageAuthor | null, processing?: boolean | null, feedback?: boolean | null, type?: MessageType | null, sources?: Array<{ __typename?: 'Source', id?: string | null, sources?: Array<string | null> | null, domainUrl?: string | null } | null> | null } | null> | null };

export type NewWorklogSubscriptionVariables = Exact<{
  input: WorklogSubscriptionInput;
}>;


export type NewWorklogSubscription = { __typename?: 'Subscription', newWorklog?: Array<{ __typename?: 'ActivityLogMessage', id?: string | null, isUser?: boolean | null, description?: string | null, showApprove?: boolean | null, type?: ActivityLogMessageType | null } | null> | null };


export const AddMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddMessageMutation, AddMessageMutationVariables>;
export const UpsertParticipantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpsertParticipant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpsertUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upsertParticipant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpsertParticipantMutation, UpsertParticipantMutationVariables>;
export const AddActivityLogDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddActivityLog"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddActivityLogInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addActivityLog"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddActivityLogMutation, AddActivityLogMutationVariables>;
export const ApproveActivityLogDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApproveActivityLog"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddApproveLogInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"approveActivityLog"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<ApproveActivityLogMutation, ApproveActivityLogMutationVariables>;
export const AddFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"AddFeedbackInput"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"file"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}},{"kind":"Argument","name":{"kind":"Name","value":"file"},"value":{"kind":"Variable","name":{"kind":"Name","value":"file"}}}]}]}}]} as unknown as DocumentNode<AddFeedbackMutation, AddFeedbackMutationVariables>;
export const AddMessageFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddMessageFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddMessageFeedbackInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addMessageFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddMessageFeedbackMutation, AddMessageFeedbackMutationVariables>;
export const MarkMessagesAsReadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MarkMessagesAsRead"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MarkMessageAsReadInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"markMessagesAsRead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<MarkMessagesAsReadMutation, MarkMessagesAsReadMutationVariables>;
export const UpdateConversationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateConversation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateConversationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateConversation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateConversationMutation, UpdateConversationMutationVariables>;
export const ActionOnDislikedMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ActionOnDislikedMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ActionOnDislikedMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"actionOnDislikedMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<ActionOnDislikedMessageMutation, ActionOnDislikedMessageMutationVariables>;
export const GenerateRequirementsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateRequirements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateRequirementsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateRequirements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GenerateRequirementsMutation, GenerateRequirementsMutationVariables>;
export const GenerateJiraTicketsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateJiraTickets"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateJiraTicketsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateJiraTickets"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GenerateJiraTicketsMutation, GenerateJiraTicketsMutationVariables>;
export const GenerateCodeFromRequirementsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateCodeFromRequirements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateCodeFromRequirementsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateCodeFromRequirements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GenerateCodeFromRequirementsMutation, GenerateCodeFromRequirementsMutationVariables>;
export const StopGenerationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StopGeneration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StopGenerationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"stopGeneration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<StopGenerationMutation, StopGenerationMutationVariables>;
export const SaveNewDocumentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"saveNewDocuments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveNewDocumentsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveNewDocuments"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<SaveNewDocumentsMutation, SaveNewDocumentsMutationVariables>;
export const DeleteDocumentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteDocument"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteDocumentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteDocument"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const DeleteFaissIndexDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteFaissIndex"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteIndexInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteFaissIndex"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<DeleteFaissIndexMutation, DeleteFaissIndexMutationVariables>;
export const GenerateSprintAnalysisReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateSprintAnalysisReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateSprintAnalysisReportInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateSprintAnalysisReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"overview"}},{"kind":"Field","name":{"kind":"Name","value":"report"}}]}}]}}]} as unknown as DocumentNode<GenerateSprintAnalysisReportMutation, GenerateSprintAnalysisReportMutationVariables>;
export const UploadTimesheetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadTimesheet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddTimesheetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadTimesheet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<UploadTimesheetMutation, UploadTimesheetMutationVariables>;
export const SaveNewCallReportsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SaveNewCallReports"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveNewDocumentsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveNewCallReports"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"option"}},{"kind":"Field","name":{"kind":"Name","value":"question"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}}]}}]} as unknown as DocumentNode<SaveNewCallReportsMutation, SaveNewCallReportsMutationVariables>;
export const AnalyzeCallTranscriptDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AnalyzeCallTranscript"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AnalyzeCallTranscriptInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"analyzeCallTranscript"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<AnalyzeCallTranscriptMutation, AnalyzeCallTranscriptMutationVariables>;
export const AnalyzeTestCasesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AnalyzeTestCases"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AnalyzeTestCasesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"analyzeTestCases"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<AnalyzeTestCasesMutation, AnalyzeTestCasesMutationVariables>;
export const GetMessagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMessages"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getMessages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"replyTo"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"author"}}]}}]}}]} as unknown as DocumentNode<GetMessagesQuery, GetMessagesQueryVariables>;
export const GetParticipantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetParticipant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getParticipant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conversations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"threadId"}},{"kind":"Field","name":{"kind":"Name","value":"threadType"}}]}}]}}]}}]} as unknown as DocumentNode<GetParticipantQuery, GetParticipantQueryVariables>;
export const GetSourceChunksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSourceChunks"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sourceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSourceChunks"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sourceId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sourceId"}}}]}]}}]} as unknown as DocumentNode<GetSourceChunksQuery, GetSourceChunksQueryVariables>;
export const GetApplicationConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetApplicationConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getApplicationConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"configKey"}},{"kind":"Field","name":{"kind":"Name","value":"chatType"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"icon"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<GetApplicationConfigQuery, GetApplicationConfigQueryVariables>;
export const GetApplicationUserConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetApplicationUserConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getApplicationUserConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"features"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"models"}},{"kind":"Field","name":{"kind":"Name","value":"override"}}]}}]}}]} as unknown as DocumentNode<GetApplicationUserConfigQuery, GetApplicationUserConfigQueryVariables>;
export const GetKplGuidelinesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetKplGuidelines"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetKplGuidelinesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getKplGuidelines"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"guidelinesText"}}]}}]}}]} as unknown as DocumentNode<GetKplGuidelinesQuery, GetKplGuidelinesQueryVariables>;
export const GetJiraSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetJiraSettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetJiraSettingsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getJiraSettings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"sprints"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"originBoardId"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]}}]} as unknown as DocumentNode<GetJiraSettingsQuery, GetJiraSettingsQueryVariables>;
export const GetLiveCustomerAgentDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLiveCustomerAgentDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLiveCustomerAgentDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"audioFileUrl"}},{"kind":"Field","name":{"kind":"Name","value":"transcript"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"time"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]} as unknown as DocumentNode<GetLiveCustomerAgentDetailsQuery, GetLiveCustomerAgentDetailsQueryVariables>;
export const NewMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"NewMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MessageSubscriptionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"processing"}},{"kind":"Field","name":{"kind":"Name","value":"feedback"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"sources"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sources"}},{"kind":"Field","name":{"kind":"Name","value":"domainUrl"}}]}}]}}]}}]} as unknown as DocumentNode<NewMessageSubscription, NewMessageSubscriptionVariables>;
export const NewWorklogDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"NewWorklog"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WorklogSubscriptionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newWorklog"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isUser"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"showApprove"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<NewWorklogSubscription, NewWorklogSubscriptionVariables>;