import React, { forwardRef } from 'react';
import { CN } from '../../../utils';
import { CSS_SCROLL } from '../../../constants';
import { useSideBarContext } from '../../../contexts';

interface ScrollableWrapperProps {
    children: React.ReactNode;
    className?: string;
}

const ScrollableWrapper = forwardRef<HTMLDivElement, ScrollableWrapperProps>(({ children }, ref) => {
    const { isCollapsed } = useSideBarContext();
    return (
        <div
            ref={ref}
            className={CN('w-full px-[10%] mx-auto py-1 flex flex-col scroll-smooth h-full sm:px-[2%]', CSS_SCROLL, {
                'px-[15%]': isCollapsed,
            })}
        >
            {children}
        </div>
    );
});

export { ScrollableWrapper };
