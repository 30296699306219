import React from 'react';
import { CN } from '../../../utils';

type PopoverProps = {
    open: boolean;
    fullscreen: boolean;
    style?: React.CSSProperties;
    className?: string;
    content: React.ReactElement;
};

const Popover = ({ open, fullscreen, style, className, content }: PopoverProps) => {
    if (!open) {
        return null;
    }

    return (
        <div
            className={CN(
                'shadow-md inset-0 flex items-center justify-center z-50 hover:shadow-lg animate-fade-up animate-once animate-ease-out',
                { 'fixed top-0 left-0 w-full h-full': fullscreen }
            )}
        >
            <div className={CN('bg-white shadow', className, { 'relative z-50 w-full': fullscreen })} style={style}>
                {content}
            </div>
        </div>
    );
};

export { Popover };
