import { IMG_KAYA_BOT_GIF } from '../../../../constants';

export const SidebarLogoSection = () => {
    return (
        <div className="sidebar_logo_section">
            <div className="sidebar_logo flex flex-col gap-y-6 pb-6 px-4 sm:px-0 items-center">
                <div className="logo w-12 h-12 flex justify-center items-center">
                    <img alt="Kaya Chatbot GIF" src={IMG_KAYA_BOT_GIF} className="rounded-[14px] sm:w-10" />
                </div>
            </div>
        </div>
    );
};
