import { FeatureType, Source } from '../../../__generated__/graphql';
import { useChatContext } from '../../../contexts';
import { CustomSource, ReferenceCard } from '../reference-card';

type ReferencesProps = {
    sources?: Source[];
};

const References = ({ sources }: ReferencesProps) => {
    const { featureType } = useChatContext();
    if (featureType === FeatureType.KayaKbRecruitment) return null;

    const getPage = (source: Source) => {
        try {
            const sources = String(source?.sources?.[0])?.split('#');
            const page = Number(sources?.[1]?.replace('page=', '') || 0);
            return page || 0;
        } catch (error) {
            console.error('Error occurred while getting page:', error);
            return 0;
        }
    };

    const initialSources: CustomSource[] =
        sources?.map(source => ({
            ...source,
            page: getPage(source),
        })) || [];

    return (
        <div className="flex flex-col items-start gap-3">
            <p className="text-lg font-bold">📚 References</p>
            <div className="flex flex-col items-start gap-3">
                {initialSources.map((source, index) => (
                    <ReferenceCard key={index} id={index + 1} source={source} featureType={featureType} />
                ))}
            </div>
        </div>
    );
};

export { References };
