import { useClickOutside } from '@mantine/hooks';
import { useState } from 'react';
import { IMG_KAYA_BOT_GIF, IMG_KAYA_POPUP_CLOSE, MOBILE_SCREEN_SIZE } from '../../constants';
import { useChatContext } from '../../contexts';
import { useMobile } from '../../hooks';
import { CN, getAutoExpand } from '../../utils';
import { Popover } from '../common';
import { ChatButton } from './ChatButton';
import { ChatContent } from './ChatContent';

const ChatbotPopup = () => {
    const [key, setKey] = useState(Date.now());
    const { userDetails, fullscreen, setFullscreen, cleanupState } = useChatContext();
    const expand = getAutoExpand(userDetails.email);

    const [open, setOpen] = useState(expand);
    const [leftOffset, setLeftOffset] = useState<number>(expand ? window.innerWidth - 100 : 0);

    const ref = useClickOutside(() => setOpen(false));
    const isMobile = useMobile();

    const onClickHandler = (leftOffset: number) => {
        setLeftOffset(leftOffset);
        setOpen(o => !o);
    };

    return (
        <div
            id="bernie-chatbot"
            ref={ref}
            className={CN('flex flex-col fixed bottom-10 z-50 max-w-[80vw] max-h-[80vh]', {
                'bottom-[4.5rem]': isMobile,
            })}
            style={{
                right: leftOffset > MOBILE_SCREEN_SIZE ? `calc(100vw - ${leftOffset - 5}px)` : 'auto',
                left: leftOffset > MOBILE_SCREEN_SIZE ? 'auto' : `${leftOffset + 75}px`,
            }}
        >
            <Popover
                key={key}
                open={open}
                fullscreen={isMobile || fullscreen}
                className="popup transition-height duration-100 ease-in-out flex flex-col justify-between border border-N-400 w-[450px] min-w-[auto]"
                style={{ height: isMobile || fullscreen ? '100%' : '75vh' }}
                content={
                    <ChatContent
                        showHeader
                        onExternalLinkClick={() => {
                            if (isMobile || fullscreen) setOpen(false);
                            setFullscreen(fullscreen => !fullscreen);
                            cleanupState();
                            setKey(Date.now());
                        }}
                    />
                }
            />
            <ChatButton
                icon={open ? IMG_KAYA_POPUP_CLOSE : IMG_KAYA_BOT_GIF}
                onClick={(leftOffset: number) => onClickHandler(leftOffset)}
            />
        </div>
    );
};

export { ChatbotPopup };
