import { gql } from '../__generated__';

export const CHAT_MESSAGES = gql(/* GraphQL */ `
    query GetMessages($input: GetMessageInput!) {
        getMessages(input: $input) {
            id
            replyTo
            content
            author
        }
    }
`);

export const GET_USER = gql(/* GraphQL */ `
    query GetParticipant($userId: ID!) {
        getParticipant(userId: $userId) {
            conversations {
                id
                title
                updatedAt
                threadId
                threadType
            }
        }
    }
`);

export const GET_SOURCE_CHUNKS = gql(/* GraphQL */ `
    query GetSourceChunks($sourceId: ID!) {
        getSourceChunks(sourceId: $sourceId)
    }
`);

export const GET_APPLICATION_CONFIGURATIONS = gql(/* GraphQL */ `
    query GetApplicationConfig {
        getApplicationConfig {
            configKey
            chatType
            description
            icon
            id
            enabled
            metadata
            title
        }
    }
`);

export const GET_APPLICATION_USER_CONFIG = gql(/* GraphQL */ `
    query GetApplicationUserConfig {
        getApplicationUserConfig {
            email
            features
            metadata
            models
            override
        }
    }
`);

export const GET_KPL_GUIDELINES = gql(/* GraphQL */ `
    query GetKplGuidelines($input: GetKplGuidelinesInput!) {
        getKplGuidelines(input: $input) {
            guidelinesText
        }
    }
`);

export const GER_JIRA_SETTINGS = gql(/* GraphQL */ `
    query GetJiraSettings($input: GetJiraSettingsInput!) {
        getJiraSettings(input: $input) {
            id
            key
            name
            sprints {
                id
                name
                originBoardId
                state
            }
        }
    }
`);

export const GET_LIVE_CUSTOMER_AGENT = gql(/* GraphQL */ `
    query GetLiveCustomerAgentDetails {
        getLiveCustomerAgentDetails {
            audioFileUrl
            transcript {
                message
                time
                type
            }
        }
    }
`);
