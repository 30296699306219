import { useViewportSize } from '@mantine/hooks';
import React from 'react';
import { CN } from '../../../utils';
import { ModalContainer } from '../modal-container';
import {
    SidebarAvatarSection,
    SidebarLogoSection,
    SidebarPrimaryBtnSection,
    SidebarSecondaryBtnSection,
} from './common';

export const Sidebar = () => {
    const { height } = useViewportSize();
    const isLandscape = height < 515;

    return (
        <React.Fragment>
            <div
                className={CN(
                    'sidebar flex flex-col justify-between items-center min-w-[68px] sm:min-w-[68px] py-4 bg-white border-r border-r-N-200 z-10',
                    {
                        'overflow-y-scroll overflow-x-hidden': isLandscape,
                    }
                )}
            >
                <div className="flex flex-col gap-y-16 flex-grow">
                    <SidebarLogoSection />
                    <SidebarPrimaryBtnSection />
                </div>
                <div className="flex flex-col gap-y-8">
                    <SidebarSecondaryBtnSection />
                    <SidebarAvatarSection />
                </div>
            </div>

            {/* All the UI modal related to the ExtendedSideBar & SideBar */}
            <ModalContainer />
        </React.Fragment>
    );
};
