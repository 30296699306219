import { ChatType, FeatureType, MessageSettingsInput, SettingsModelType } from '../__generated__/graphql';
import { DEFAULT_MODELS, MODEL_TYPES } from '../constants';
import { AppConfigType, AppUsersConfigType, ModelListType } from '../contexts';
import { logger } from './logger';

export function getAutoExpand(email: string): boolean {
    return true;
}

export function getFullScreenOption(): boolean {
    return true;
}

export function getImprovedResponse(email: string): string {
    return localStorage.getItem(`aia_response_type-${email}`) || '';
}

export function getCurrentUserFromConfig(users: AppUsersConfigType[], email: string): AppUsersConfigType | null {
    const defaultUserConfig = users.find(user => user.email === '*');
    const currentUserConfig = users.find(user => user.email === email);
    const currentUserDomainConfig = users.find(user => user.email === email.split('@')[1]);

    if (currentUserConfig?.override) return currentUserConfig;
    if (currentUserDomainConfig) return currentUserDomainConfig;

    return currentUserConfig || defaultUserConfig || null;
}

export function getFeaturesToDisplay(users: AppUsersConfigType[], email: string): string {
    return (
        localStorage.getItem(`aia_visible_features-${email}`) || getCurrentUserFromConfig(users, email)?.features || '*'
    );
}

export function getDefaultTabToDisplay(users: AppUsersConfigType[], email: string): FeatureType {
    return getCurrentUserFromConfig(users, email)?.metadata?.default?.feature || FeatureType.General;
}

export function getDefaultSettings(users: AppUsersConfigType[], email: string): MessageSettingsInput {
    const defaultUserConfig = getCurrentUserFromConfig(users, email)?.metadata;

    return {
        feature: defaultUserConfig?.default?.feature || FeatureType.General,
        model: defaultUserConfig?.default?.model || SettingsModelType.OpenAi,
        type: getImprovedResponse(email),
        hasTemporaryFiles: false,
        options: {
            dynamicPrompts: defaultUserConfig?.default?.dynamicPrompts || false,
            webSearch: defaultUserConfig?.default?.webSearch || false,
            noLLM: defaultUserConfig?.default?.noLLM || false,
        },
    };
}

export function getFilteredFeaturesByEmail(
    users: AppUsersConfigType[],
    features: AppConfigType[],
    email: string
): AppConfigType[] {
    try {
        const featureTypesToDisplay = getFeaturesToDisplay(users, email);
        let finalFeatures: AppConfigType[] = [];

        if (featureTypesToDisplay === '*') {
            finalFeatures = [...features];
        } else {
            const featuresTypeArray = featureTypesToDisplay.split(',').map(type => type.trim());
            finalFeatures = features.filter(feature => featuresTypeArray.includes(feature.configKey));
        }

        finalFeatures.sort((a, b) => {
            if (a.chatType === ChatType.Assistant && b.chatType !== ChatType.Assistant) return -1;
            if (a.chatType !== ChatType.Assistant && b.chatType === ChatType.Assistant) return 1;
            return a.id - b.id;
        });

        return finalFeatures;
    } catch (error) {
        logger.error('Error filtering features by email:', error);
        return features;
    }
}

export function getFilteredModelsByUser(users: AppUsersConfigType[], email: string): ModelListType[] {
    const models = getCurrentUserFromConfig(users, email)?.models ?? DEFAULT_MODELS;
    return MODEL_TYPES.filter(model => models.includes(model.model));
}

export function getCustomMessage(email: string): string {
    return localStorage.getItem(`aia_message-${email}`) || '';
}

export function clearCustomization(email: string, message: string): void {
    const exitMessage = localStorage.getItem(`aia_exit_message`) || 'Thank you Bernie';

    if (message === exitMessage) {
        localStorage.removeItem(`aia_message-${email}`);
    }
}

export function getLocalGuidelines(email: string): string[] | null {
    try {
        return JSON.parse(localStorage.getItem(`aia_get_started_questions-${email}`) || 'null');
    } catch {
        return null;
    }
}

export function getCallReports(email: string): boolean {
    return localStorage.getItem(`aia_call_reports-${email}`) === 'enabled';
}

export function getCallTranscripts(email: string): boolean {
    return localStorage.getItem(`aia_call_transcripts-${email}`) === 'enabled';
}

export function getCallReportsUser(email: string): string {
    return localStorage.getItem(`aia_user_call_reports-${email}`) || 'Ishan Jayasekera';
}

export function getTestCaseSuccessMessage(): string {
    return (
        localStorage.getItem(`aia_test_case_success`) ||
        `Test Cases added successfully ✅, You can now proceed with the questions.`
    );
}
