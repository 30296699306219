import React, { useCallback, useEffect, useRef, useState } from 'react';

type TChatButton = {
    icon: string;
    onClick: (left: number) => void;
};

const ChatButton = ({ icon, onClick }: TChatButton) => {
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [startX, setStartX] = useState<number>(0);
    const [leftOffset, setLeftOffset] = useState<number>(0);

    const handleMouseDown = useCallback((e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.clientX);
        if (buttonRef.current) {
            setLeftOffset(buttonRef.current.getBoundingClientRect().left);
            buttonRef.current.style.transition = 'none';
        }
    }, []);

    const handleMouseUp = useCallback(() => {
        if (isDragging && buttonRef.current) {
            setIsDragging(false);
            buttonRef.current.style.transition = 'left 0.3s ease-out'; // Add a smooth transition effect
        }
    }, [isDragging]);

    const handleMouseMove = useCallback(
        (e: any) => {
            if (isDragging && buttonRef.current) {
                const offsetX = e.clientX - startX;
                const newLeft = leftOffset + offsetX;

                // Calculate the viewport width
                const viewportWidth = window.innerWidth;

                // Calculate the maximum allowed left position
                const maxLeft = viewportWidth - buttonRef.current.offsetWidth;

                // Ensure the button stays within the viewport
                if (newLeft >= 0 && newLeft <= maxLeft) {
                    buttonRef.current.style.left = newLeft + 'px';
                    setLeftOffset(newLeft); // Update leftOffset
                }
            }
        },
        [isDragging, startX, leftOffset]
    );

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            // Clean up the event listeners when the component unmounts
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    return (
        <div
            ref={buttonRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseUp}
            onClick={() => onClick(leftOffset)}
            draggable="true"
            className="z-999 cursor-pointer fixed bottom-[2rem] right-[2rem] w-16 h-16 rounded-full flex items-center justify-center text-white bg-white text-2xl shadow-md shadow-[#1b242b9a]"
        >
            <img
                onDragStart={e => {
                    e.preventDefault();
                }}
                draggable="true"
                src={icon}
                alt="Chatbot"
                className="h-full w-full rounded-[50%] inline-block border-none hover:scale-110 transition-all duration-250 ease-in-out cursor-grab active:cursor-grabbing"
            />
        </div>
    );
};

export { ChatButton };
