import { Avatar, Dropdown, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { useChatHeader, useMobile } from '../../hooks';
import { truncateWithEllipsis, uniqueId } from '../../utils';
import { MobileMenuButton } from '../common';

const ChatHeader = () => {
    const { title, label, currentModelImage, dropDownModels, description } = useChatHeader();
    const isMobile = useMobile();

    return (
        <div className="chat_content__header bg-white border-b border-b-N-200 max-h-[69px] flex items-center px-6 py-6 justify-between sm:py-3 sm:px-3">
            <div className="flex items-center gap-x-2 sm:items-center">
                <Avatar shape="square" imageURL={currentModelImage} type="image" size={isMobile ? 'md' : 'lg'} />
                <div className="flex flex-col">
                    <h6 className="text-N-600 font-500 text-lg sm:text-md" title={title}>
                        {truncateWithEllipsis(title, isMobile ? 25 : 80)}
                    </h6>
                    <div className="flex gap-x-2 items-center">
                        <div className="relative flex items-center gap-x-1 justify-center cursor-pointer py-[1px] px-2 rounded bg-B-100">
                            <p className="text-xs text-B-700 relative top-[1px]">
                                {truncateWithEllipsis(String(label), isMobile ? 20 : 100)}
                            </p>
                            <Tooltip
                                id={uniqueId()}
                                place="bottom"
                                subText={description}
                                variant="dark"
                                tooltipType="detailed"
                            >
                                <i className="ri-question-line text-sm text-B-700 relative" />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Dropdown
                    defaultSelectedItem={1}
                    wrapperClassName="absolute right-[20px] min-w-[200px]"
                    header={{
                        type: 'title',
                        title: <p className="text-md text-center font-500 text-N-900 p-0 m-0 pt-1">Select Model</p>,
                    }}
                    dropdownItems={dropDownModels}
                    selectionType="single"
                    triggerAction="onClick"
                    showSelectedCheck
                >
                    <i className="px-2 cursor-pointer text-h6 text-N-600 ri-equalizer-3-fill" />
                </Dropdown>
                <MobileMenuButton />
            </div>
        </div>
    );
};

export { ChatHeader };
