import { Avatar, Dropdown } from '@kaya/kaya-ui-design-system-pb';
import { useChatContext } from '../../../../contexts';
import { KeycloakService } from '../../../../services';
import { useMobile } from '../../../../hooks';

export const SidebarAvatarSection = () => {
    const { userDetails } = useChatContext();
    const isMobile = useMobile();

    return (
        <Dropdown
            selectionType="single"
            triggerAction="onClick"
            openningPosition="above"
            wrapperClassName="min-w-32 bottom-12 left-4"
            dropdownItems={[
                {
                    id: 1,
                    leadingNodeType: 'icon',
                    icon: 'ri-logout-box-line',
                    label: 'Logout',
                    onClick: () => {
                        KeycloakService.doLogout({});
                    },
                },
            ]}
        >
            <div className="sidebar_avatar flex justify-center items-center cursor-pointer">
                <Avatar
                    size={'md'}
                    type="image"
                    supportIconStatus="online"
                    hasSupportIcon
                    imageURL={userDetails.imageUrl}
                />
            </div>
        </Dropdown>
    );
};
