import { TextField } from '@kaya/kaya-ui-design-system-pb';
import { useDebouncedValue } from '@mantine/hooks';
import { useState } from 'react';
import { CSS_LIST } from '../../../constants';
import { AppConfigType, useChatContext, useSideBarContext } from '../../../contexts';
import { getFilteredFeaturesByEmail } from '../../../utils';
import { MobileMenuButton } from '../buttons';
import { KPLCard } from '../kpl-card';
import { ChatType, FeatureType } from '../../../__generated__/graphql';

export const KPLList = () => {
    const { handleMobileMenuIconClick, isCollapsed, setShowFeedbackModal } = useSideBarContext();
    const { userDetails, appConfig, featureType, usersConfig, handleAppConfigSelection } = useChatContext();
    const features: AppConfigType[] = getFilteredFeaturesByEmail(usersConfig, appConfig, userDetails.email);
    const [value, setValue] = useState('');
    const [debounced] = useDebouncedValue(value, 200);

    const KPL_LIST = features
        .filter(item => item.title.toLowerCase().includes(debounced.toLowerCase()))
        .map((feature, index) => {
            const actionKpl = [
                ChatType.ChatWithDocuments,
                ChatType.Export,
                ChatType.Feedback,
                ChatType.Timesheet,
                ChatType.AddTimesheet,
            ].includes(feature.chatType);

            return {
                id: index,
                feature: feature,
                currentFeatureType: featureType,
                actionKpl,
                onClick: () => {
                    if (feature.configKey === FeatureType.Feedback) {
                        setShowFeedbackModal(true);
                    } else {
                        handleAppConfigSelection(feature);
                        handleMobileMenuIconClick();
                    }
                },
            };
        });

    return (
        <div>
            <div className="flex justify-between items-center border-b border-b-N-200">
                <div className="chat_history_title py-[20px] sm:py-4 px-6 sm:px-3 flex gap-x-[10px] items-center border-b border-b-N-200">
                    <p className="text-lg sm:text-lg font-600 text-N-900">Explore KPLs</p>
                </div>
                <MobileMenuButton />
            </div>
            <div className="chat_history_input p-3 border-b border-b-N-200">
                <TextField
                    value={value}
                    onChange={(event: any) => setValue(event.currentTarget.value)}
                    view="default"
                    wrapperClassName={`w-full ${isCollapsed ? 'invisible' : 'visible'}`}
                    placeholder="Search KPLs"
                    iconBefore="ri-search-line"
                />
            </div>
            <div className={CSS_LIST}>
                {KPL_LIST.map((item: any, index: number) => (
                    <KPLCard key={index} {...item} />
                ))}
            </div>
        </div>
    );
};
