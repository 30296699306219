import { Dropdown } from '@kaya/kaya-ui-design-system-pb';
import { DropdownItemProps } from '@kaya/kaya-ui-design-system-pb/dist/src/components/atoms/Dropdown/DropdownItem';
import { FeatureType, Source } from '../../../__generated__/graphql';
import { truncateWithEllipsis } from '../../../utils';

export type CustomSource = Source & { page: number };

export const ReferenceCard = ({
    id,
    featureType,
    source,
}: {
    id: number;
    featureType: string;
    source: CustomSource;
}) => {
    const dropdownItems: DropdownItemProps[] =
        source?.sources?.map((value, index) => ({
            id: index,
            label: truncateWithEllipsis(String(value)),
            icon: 'ri-links-fill text-lg',
            leadingNodeType: 'icon',
            onClick: () => window.open(String(value), '_blank'),
        })) || [];

    const getFileName = (url: string) => {
        try {
            const regex = /\/([^\/?#;]+(\.pdf|\.txt|\.csv|\.docx))(?:\?|#|;|$)/i;
            const match = url.match(regex);
            return match ? match[1] : url;
        } catch (error) {
            console.error('Error occurred while getting file name:', error);
            return url;
        }
    };

    const getSanitizedURL = (url: string) => {
        try {
            const fileName = getFileName(String(source.domainUrl));
            return fileName ? truncateWithEllipsis(decodeURIComponent(fileName), 50) : url;
        } catch (error) {
            console.error('Error occurred while sanitizing URL:', error);
            return url;
        }
    };

    const urlMatchesRegex = (url: string) => {
        const regex = /\/([^\/?#;]+(\.pdf|\.txt|\.csv|\.docx))(?:\?|#|;|$)/i;
        return regex.test(url);
    };

    const getReference = (source: CustomSource) => {
        const sanitizedURL = getSanitizedURL(String(source.domainUrl));
        const pageInfo = urlMatchesRegex(String(source.domainUrl)) ? ` | Page: ${source.page}` : '';
        return `${sanitizedURL}${pageInfo}`;
    };

    const getReferenceColor = (source: CustomSource) => {
        if (
            featureType === FeatureType.ClientKbPerceptive &&
            !String(source.domainUrl).includes('kaya-aia-knowledge-base-docs')
        ) {
            return {
                border: 'border-A-400',
                bg: 'bg-A-200',
                text: 'text-A-600',
                source: 'text-A-700',
            };
        }

        return {
            border: 'border-B-400',
            bg: 'bg-B-200',
            text: 'text-B-600',
            source: 'text-B-700',
        };
    };

    const referenceColor = getReferenceColor(source);

    return (
        <Dropdown
            wrapperClassName="min-w-[200px]"
            triggerAction="onClick"
            selectionType="single"
            openningPosition="above"
            dropdownItems={dropdownItems}
        >
            <div className={`flex items-center ${referenceColor.border} rounded-[4px] border cursor-pointer`}>
                <div
                    className={`${referenceColor.bg} px-2 rounded-[3px_0px_0px_3px] text-xs py-[1px] leading-4 pt-1 cursor-pointer ${referenceColor.text}`}
                >
                    <p>{id}</p>
                </div>
                <div className="px-2 text-xs">
                    <p className={`${referenceColor.source}`}>{getReference(source)}</p>
                </div>
            </div>
        </Dropdown>
    );
};
