import { Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { SideBarButtonSelectionTypes, useSideBarContext } from '../../../contexts';
import { CN, uniqueId } from '../../../utils';

interface ISideBarButtonProps {
    buttonSelectionType?: SideBarButtonSelectionTypes;
    icon: string;
    type: 'primary' | 'secondary' | 'action';
    toolTipLabel: string;
    onClick?: () => void;
}

export const SideBarButton = ({ onClick, toolTipLabel, buttonSelectionType, icon, type }: ISideBarButtonProps) => {
    const { isCollapsed, setIsCollapsed, sideBarButtonSelection, setSideBarButtonSelection } = useSideBarContext();

    const handleButtonClick = () => {
        if (type !== 'action' && buttonSelectionType) {
            setSideBarButtonSelection(buttonSelectionType);
            isCollapsed && setIsCollapsed(false);
        }
        onClick?.();
    };

    return (
        <Tooltip
            id={(buttonSelectionType as string) || '_' + uniqueId()}
            place="left"
            variant="dark"
            tooltipType="minimal"
            title={toolTipLabel}
            className="!pb-1"
        >
            <button
                onClick={handleButtonClick}
                className={CN('sidebar_btn w-10 h-10 sm:w-10 sm:h-10 rounded-[4px]', {
                    'group bg-B-600 duration-700 animate-jump animate-once animate-duration-200':
                        sideBarButtonSelection === buttonSelectionType && type !== 'action',
                    'hover:bg-N-200 duration-300': sideBarButtonSelection !== buttonSelectionType,
                })}
            >
                <i
                    className={CN(`${icon} text-h6 sm:text-h6 ml-[1.5px] mt-[0.75px]`, {
                        'text-white': sideBarButtonSelection === buttonSelectionType && type !== 'action',
                        'text-N-600 duration-1000': sideBarButtonSelection !== buttonSelectionType,
                    })}
                />
            </button>
        </Tooltip>
    );
};
