/* eslint-disable */
import { useMutation, useQuery } from '@apollo/client';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    CallReportOptions,
    ChatType,
    DocumentPersistenceType,
    FeatureType,
    MessageAuthor,
    MessageSettingsInput,
    MessageType,
    SettingsModelType,
    Source,
} from '../__generated__/graphql';
import { FEATURE_MODEL_TEMP_ID, JIRA_OPTIONS, MAX_TEXT_LENGTH } from '../constants';
import {
    DELETE_TEMPORARY_DOCUMENTS,
    GET_APPLICATION_CONFIGURATIONS,
    GET_APPLICATION_USER_CONFIG,
    UPSERT_USER,
} from '../graphql';
import { downloadMessagesAsPDF } from '../services';
import {
    gerCurrentDate,
    getCurrentTime,
    getDefaultSettings,
    getDefaultTabToDisplay,
    getFullScreenOption,
    logger,
    uniqueId,
} from '../utils';

export interface IJiraOptions {
    jiraUserEmail: string;
    jiraUserToken: string;
    jiraInstanceName: string;
    jiraProjectKey: string;
}

export interface IMessage {
    id: string;
    content: string;
    author: MessageAuthor;
    processing?: boolean;
    feedback?: boolean;
    time?: string;
    type?: MessageType;
    sources?: Source[];
}

export interface IChatbotUserDetails {
    id: string;
    imageUrl: string;
    name: string;
    sessionId: string;
    email: string;
}

export interface FeedbackData {
    technicalAssistance: number;
    employeeEngagement: number;
    dailyUsage: number;
    file: File | null;
}

export interface ModelListType {
    name: string;
    model: SettingsModelType;
    imageURL: string;
}

export interface AppConfigType {
    id: number;
    configKey: FeatureType;
    description: string;
    icon: string;
    enabled: boolean;
    chatType: ChatType;
    metadata: {
        webSearch: {
            engine: string;
            enabled: boolean;
            search_results: number;
        };
        dynamicPrompts: {
            enabled: boolean;
        };
        noLLM: {
            enabled: boolean;
        };
        welcomeMessage: string;
    };
    title: string;
}

export type Conversation = {
    id: string;
    title: string;
    feature: AppConfigType;
    updatedAt: string;
};

export interface AppUsersConfigType {
    email: string;
    features: string;
    models: string;
    override: boolean;
    metadata: {
        default: {
            feature: FeatureType;
            model: string;
            dynamicPrompts: boolean;
            webSearch: boolean;
            noLLM: boolean;
        };
    };
}

interface IChatContext {
    userDetails: IChatbotUserDetails;
    userFirstName: string;
    conversationId: string;
    setConversationId: React.Dispatch<React.SetStateAction<string>>;
    currentMessage: string;
    setCurrentMessage: React.Dispatch<React.SetStateAction<string>>;
    messages: IMessage[];
    setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>;
    timesheets: any[];
    setTimesheets: React.Dispatch<React.SetStateAction<any[]>>;
    chatType: ChatType;
    setChatType: React.Dispatch<React.SetStateAction<ChatType>>;
    featureType: FeatureType;
    setFeatureType: React.Dispatch<React.SetStateAction<FeatureType>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    fullscreen: boolean;
    setFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
    timesheetFormData: any;
    setTimesheetFormData: React.Dispatch<React.SetStateAction<any>>;
    isValid: boolean;
    settings: MessageSettingsInput;
    setSettings: React.Dispatch<React.SetStateAction<MessageSettingsInput>>;
    handleAppConfigSelection: (config: AppConfigType) => void;
    handleNewChat: () => void;
    handleConversationSelection: (conversation: Conversation) => void;
    jiraOptions: IJiraOptions;
    setJiraOptions: React.Dispatch<React.SetStateAction<IJiraOptions>>;
    showJiraButtons: { business: boolean; technical: boolean };
    setShowJiraButtons: React.Dispatch<React.SetStateAction<{ business: boolean; technical: boolean }>>;
    streaming: boolean;
    lastMessageId: string;
    appConfig: AppConfigType[];
    usersConfig: AppUsersConfigType[];
    currentAppConfig: AppConfigType | null;
    chatDocuments: File[];
    setChatDocuments: React.Dispatch<React.SetStateAction<File[]>>;
    documentPersistanceType: DocumentPersistenceType | null;
    setDocumentPersistanceType: React.Dispatch<React.SetStateAction<DocumentPersistenceType>>;
    cleanupState: () => void;
    callReportQuestions: CallReportOptions[];
    setCallReportQuestions: React.Dispatch<React.SetStateAction<CallReportOptions[]>>;
    technicalRequirementValue: string;
    setTechnicalRequirementValue: React.Dispatch<React.SetStateAction<string>>;
    conversation: Conversation | null;
    setConversation: React.Dispatch<React.SetStateAction<Conversation | null>>;
    handleExportToPDF: () => void;
}
interface IChatContextProvider {
    userDetails: IChatbotUserDetails;
    children: ReactNode;
}

const ChatContext = createContext<IChatContext>({
    userDetails: { id: '', imageUrl: '', name: '', sessionId: '', email: '' },
    userFirstName: '',
    conversationId: '',
    setConversationId: () => {},
    currentMessage: '',
    setCurrentMessage: () => {},
    messages: [],
    setMessages: () => {},
    timesheets: [],
    setTimesheets: () => {},
    chatType: ChatType.Assistant,
    setChatType: () => {},
    featureType: FeatureType.General,
    setFeatureType: () => {},
    loading: false,
    setLoading: () => {},
    fullscreen: false,
    setFullscreen: () => {},
    timesheetFormData: { date: gerCurrentDate() },
    setTimesheetFormData: () => {},
    isValid: false,
    settings: {
        feature: '',
        model: '',
        type: '',
        options: { dynamicPrompts: false, webSearch: false, noLLM: false },
        hasTemporaryFiles: false,
    },
    setSettings: () => {},
    handleAppConfigSelection: () => {},
    handleNewChat: () => {},
    handleConversationSelection: () => {},
    jiraOptions: JIRA_OPTIONS,
    setJiraOptions: () => {},
    showJiraButtons: { business: false, technical: false },
    setShowJiraButtons: () => {},
    streaming: false,
    lastMessageId: '',
    appConfig: [],
    usersConfig: [],
    currentAppConfig: null,
    chatDocuments: [],
    setChatDocuments: () => {},
    documentPersistanceType: null,
    setDocumentPersistanceType: () => {},
    cleanupState: () => {},
    callReportQuestions: [],
    setCallReportQuestions: () => {},
    technicalRequirementValue: '',
    setTechnicalRequirementValue: () => {},
    conversation: null,
    setConversation: () => {},
    handleExportToPDF: () => {},
});

export const ChatContextProvider: React.FC<IChatContextProvider> = ({ userDetails, children }) => {
    const [upsertUser] = useMutation(UPSERT_USER);
    const [deleteTemporaryFiles] = useMutation(DELETE_TEMPORARY_DOCUMENTS);
    const { data: applicationConfigData } = useQuery(GET_APPLICATION_CONFIGURATIONS, { fetchPolicy: 'cache-first' });
    const { data: applicationUserConfigData } = useQuery(GET_APPLICATION_USER_CONFIG, { fetchPolicy: 'cache-first' });
    const appConfig = (applicationConfigData?.getApplicationConfig as AppConfigType[]) || [];
    const usersConfig = (applicationUserConfigData?.getApplicationUserConfig as AppUsersConfigType[]) || [];

    const defaultTab = getDefaultTabToDisplay(usersConfig, userDetails.email);
    const defaultSettings = getDefaultSettings(usersConfig, userDetails.email);

    const [callReportQuestions, setCallReportQuestions] = useState<CallReportOptions[]>([]);
    const [jiraOptions, setJiraOptions] = useState<IJiraOptions>(JIRA_OPTIONS);
    const [conversationId, setConversationId] = useState<string>(crypto.randomUUID());
    const [conversation, setConversation] = useState<Conversation | null>(null);
    const [currentMessage, setCurrentMessage] = useState('');
    const [messages, setMessages] = useState<any[]>([]);
    const [timesheets, setTimesheets] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [fullscreen, setFullscreen] = useState(getFullScreenOption());
    const [chatType, setChatType] = useState<ChatType>(ChatType.Assistant);
    const [featureType, setFeatureType] = useState<FeatureType>(defaultTab);
    const [submitted, setSubmitted] = useState('');
    const [technicalRequirementValue, setTechnicalRequirementValue] = useState('');
    const [settings, setSettings] = useState(defaultSettings);
    const [chatDocuments, setChatDocuments] = useState<File[]>([]);
    const [documentPersistanceType, setDocumentPersistanceType] = useState<DocumentPersistenceType>(
        DocumentPersistenceType.Temporary
    );
    const [feedback, setFeedback] = useState<FeedbackData>({
        technicalAssistance: 0,
        employeeEngagement: 0,
        dailyUsage: 0,
        file: null,
    });
    const [timesheetFormData, setTimesheetFormData] = useState({
        date: gerCurrentDate(),
    });
    const [showJiraButtons, setShowJiraButtons] = useState({
        business: false,
        technical: false,
    });

    const userFirstName = userDetails.name?.split(' ')?.[0] || '';
    const streaming = Boolean(messages?.slice(-1)[0]?.processing);
    const lastMessageId = messages?.slice(-1)[0]?.id || '';
    const isMessageProcessing = loading || streaming;
    const isValid = currentMessage.trim() !== '' && currentMessage?.length <= MAX_TEXT_LENGTH && !isMessageProcessing;
    const currentAppConfig = appConfig.find(config => config.configKey === featureType) || null;

    const deleteTemporaryDocuments = async (conversationId: string) => {
        try {
            await deleteTemporaryFiles({
                variables: {
                    input: {
                        conversationId,
                        userId: userDetails.id,
                    },
                },
            });
        } catch (error) {
            logger.log(error);
        }
    };

    const resetDocumentHandlingState = () => {
        setDocumentPersistanceType(DocumentPersistenceType.Temporary);
        setChatDocuments([]);
    };

    const cleanupState = () => {
        deleteTemporaryDocuments(conversationId);
        resetDocumentHandlingState();
    };

    const handleExportToPDF = () => {
        downloadMessagesAsPDF(messages, conversationId, userDetails);
    };

    const createNewConversation = () => {
        const newConversationId = crypto.randomUUID();
        setConversationId(newConversationId);
        setConversation(null);
        setLoading(false);
        logger.log('New conversation id is set: ' + newConversationId);
    };

    const setupNewConversation = (configKey: FeatureType, chatType: ChatType) => {
        setFeatureType(configKey);
        setChatType(chatType);
        setSettings(s => ({
            ...s,
            feature: configKey,
            hasTemporaryFiles: false,
            options: { dynamicPrompts: false, webSearch: false, noLLM: false },
        }));
        resetDocumentHandlingState();
        createNewConversation();
    };

    const switchToNewKPL = (selectedConfig: AppConfigType) => {
        setFeatureType(selectedConfig.configKey);
        setChatType(selectedConfig.chatType);
        setSettings(s => ({
            ...s,
            hasTemporaryFiles: false,
            feature: selectedConfig.configKey,
        }));

        if (currentAppConfig?.configKey !== selectedConfig.configKey && messages.length > 0) {
            setMessages(messages => {
                const filteredMessages = messages.filter(
                    message => message.type !== MessageType.TimesheetInput && message.type !== MessageType.DocumentInput
                );

                const currentTime = getCurrentTime();
                const hasFeatureChangeInput = messages[messages.length - 1]?.id.includes(FEATURE_MODEL_TEMP_ID);

                const updatedMessages = hasFeatureChangeInput ? filteredMessages.slice(0, -1) : filteredMessages;

                const modelChangeMessage = {
                    id: `${FEATURE_MODEL_TEMP_ID}_${uniqueId()}`,
                    content: selectedConfig.title,
                    author: MessageAuthor.System,
                    time: currentTime,
                };

                return [...updatedMessages, modelChangeMessage];
            });
        }
    };

    const handleNewChat = () => {
        const key = currentAppConfig ? currentAppConfig.configKey : FeatureType.General;
        const chatType = currentAppConfig ? currentAppConfig.chatType : ChatType.Assistant;
        setupNewConversation(key, chatType);
    };

    const handleConversationSelection = (conversation: Conversation) => {
        const featureType = conversation.feature.configKey || FeatureType.General;

        resetDocumentHandlingState();
        setConversation(conversation);
        setConversationId(conversation.id);
        setSettings(s => ({ ...s, feature: featureType }));
        setFeatureType(featureType);
    };

    const handleAppConfigSelection = (selectedConfig: AppConfigType) => {
        if (chatType !== ChatType.Assistant) {
            createNewConversation();
        }

        switch (selectedConfig.chatType) {
            case ChatType.Export:
                handleExportToPDF();
                break;

            case ChatType.ChatWithDocuments:
                if (
                    currentAppConfig?.chatType !== ChatType.Assistant ||
                    currentAppConfig?.configKey === FeatureType.General
                ) {
                    toast.error('Please select a KPL to proceed!');
                    return;
                }

                setMessages(messages => {
                    const currentType = MessageType.DocumentInput;
                    const hasCurrentInput = messages.some(message => message.type === currentType);

                    if (!hasCurrentInput) {
                        return [
                            ...messages,
                            {
                                id: crypto.randomUUID(),
                                type: currentType,
                                author: MessageAuthor.User,
                                content: 'Upload your document here.',
                            },
                        ];
                    }

                    return messages;
                });

                break;

            case ChatType.AddTimesheet:
                if (
                    currentAppConfig?.configKey !== FeatureType.KayaFlexForce &&
                    currentAppConfig?.configKey !== FeatureType.KayaKbDev
                ) {
                    toast.error('Timesheet is not supported for this KPL!');
                    return;
                }
                cleanupState();
                setMessages(messages => {
                    const currentType = MessageType.TimesheetInput;
                    const hasCurrentInput = messages[messages.length - 1]?.type === currentType;

                    if (!hasCurrentInput) {
                        return [
                            ...messages,
                            {
                                id: crypto.randomUUID(),
                                type: currentType,
                                author: MessageAuthor.User,
                                content: 'Upload your task sheet here.',
                            },
                        ];
                    }

                    return messages;
                });

                break;

            default:
                cleanupState();
                switchToNewKPL(selectedConfig);
                break;
        }
    };

    useEffect(() => {
        const getUserId = async () => {
            const { id: userId } = userDetails;

            if (userId) {
                upsertUser({ variables: { input: userDetails } });
            }
        };

        if (userDetails) getUserId();
    }, [userDetails]);

    useEffect(() => {
        if (usersConfig && usersConfig.length !== 0) {
            const user = usersConfig.find(user => user.email === userDetails.email);

            if (user) {
                const defaultTab = getDefaultTabToDisplay(usersConfig, userDetails.email);
                const defaultSettings = getDefaultSettings(usersConfig, userDetails.email);

                setFeatureType(defaultTab);
                setSettings(defaultSettings);
            }
        }
    }, [usersConfig]);

    return (
        <ChatContext.Provider
            value={{
                userDetails,
                userFirstName,
                conversationId,
                setConversationId,
                currentMessage,
                setCurrentMessage,
                messages,
                setMessages,
                timesheets,
                setTimesheets,
                chatType,
                setChatType,
                featureType,
                setFeatureType,
                loading,
                setLoading,
                fullscreen,
                setFullscreen,
                timesheetFormData,
                setTimesheetFormData,
                isValid,
                settings,
                setSettings,
                handleAppConfigSelection,
                handleNewChat,
                handleConversationSelection,
                jiraOptions,
                setJiraOptions,
                showJiraButtons,
                setShowJiraButtons,
                streaming,
                lastMessageId,
                appConfig,
                usersConfig,
                currentAppConfig,
                chatDocuments,
                setChatDocuments,
                documentPersistanceType,
                setDocumentPersistanceType,
                cleanupState,
                callReportQuestions,
                setCallReportQuestions,
                technicalRequirementValue,
                setTechnicalRequirementValue,
                conversation,
                setConversation,
                handleExportToPDF,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = (): IChatContext => {
    const context = useContext(ChatContext);

    if (!context) {
        throw new Error('useChatContext must be used within a ChatContextProvider');
    }

    return context;
};
