import { useLazyQuery } from '@apollo/client';
import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { useEffect } from 'react';
import { useChatContext } from '../../../contexts';
import { GET_KPL_GUIDELINES } from '../../../graphql';
import { getLocalGuidelines, logger } from '../../../utils';
import { PromptCard } from '../prompt-card';

const WelcomeMessage = ({ submitMessage }: { submitMessage: Function }) => {
    const { userDetails, currentAppConfig, userFirstName } = useChatContext();

    const [getGuidelines, { data, loading: guidelinesLoading }] = useLazyQuery(GET_KPL_GUIDELINES, {
        fetchPolicy: 'cache-first',
    });

    const guidelines =
        getLocalGuidelines(userDetails.email) || data?.getKplGuidelines?.map(g => g?.guidelinesText || '') || [];

    useEffect(() => {
        if (currentAppConfig?.configKey) {
            getGuidelines({ variables: { input: { configKey: currentAppConfig.configKey } } });
        }
    }, [currentAppConfig?.configKey, getGuidelines]);

    const handleGuidelineClick = (question: string) => {
        try {
            submitMessage(question);
        } catch (error) {
            logger.error('Error in handleGuidelineClick', error);
        }
    };

    return (
        <div className="w-full h-full flex flex-col justify-center items-center gap-y-8 sm:justify-evenly">
            <p className="text-N-900 font-600 text-h4 sm:text-center sm:text-[20px]">
                Hey {userFirstName}! How may I assist you today? 🚀
            </p>
            {guidelinesLoading && <SpinLoader />}
            <div className="grid grid-cols-2 gap-6 sm:grid-cols-1 sm:gap-y-6 pb-2">
                {guidelines.map((question: string, index: number) => (
                    <PromptCard
                        key={index}
                        title="Explain"
                        description={question}
                        icon="ri-question-fill"
                        onClick={() => handleGuidelineClick(question)}
                    />
                ))}
            </div>
        </div>
    );
};

export { WelcomeMessage };
