import { SideBarButtonSelectionTypes, useChatContext, useSideBarContext } from '../../../../contexts';
import { useMobile } from '../../../../hooks';
import { SideBarButton } from '../../buttons';

export const SidebarSecondaryBtnSection = () => {
    const { setIsCollapsed, isCollapsed } = useSideBarContext();
    const { handleExportToPDF } = useChatContext();
    const isMobile = useMobile();

    return (
        <div className="sidebar_secondary_btn_section flex flex-col gap-y-5 px-4 sm:px-0 items-center justify-center">
            <SideBarButton
                toolTipLabel="Settings"
                type="primary"
                icon="ri-settings-3-line"
                buttonSelectionType={SideBarButtonSelectionTypes.SettingSelection}
            />
            <SideBarButton
                toolTipLabel="Export Chat"
                type="action"
                icon="ri-chat-download-line"
                onClick={handleExportToPDF}
            />
            {!isMobile && (
                <SideBarButton
                    toolTipLabel={isCollapsed ? 'Maximize' : 'Minimize'}
                    type="action"
                    icon={isCollapsed ? 'ri-expand-right-line' : 'ri-expand-left-line'}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                />
            )}
        </div>
    );
};
