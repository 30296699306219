import { ChatType } from '../../../__generated__/graphql';
import { useChatContext } from '../../../contexts';
import { ChatActions } from '../../chat-bot/ChatActions';
import { ChatHeader } from '../../chat-bot/ChatHeader';
import {
    GenerateTechnicalSpecContent,
    LiveCustomerAgent,
    MessageContent,
    SprintAnalysisReport,
    TimesheetContent,
} from '../../pages';

const ChatTypeContent = {
    [ChatType.Assistant]: MessageContent,
    [ChatType.Timesheet]: TimesheetContent,
    [ChatType.GenerateTechnicalSpec]: GenerateTechnicalSpecContent,
    [ChatType.SprintAnalysisReport]: SprintAnalysisReport,
    [ChatType.LiveCustomerAgent]: LiveCustomerAgent,
};

export const ChatContentContainer = () => {
    const { chatType } = useChatContext();
    const ChatContentComponent = ChatTypeContent[chatType as keyof typeof ChatTypeContent];
    const showChatActions = [ChatType.Assistant, ChatType.Timesheet].includes(chatType);

    return (
        <div className="chat_content_container w-full h-full flex flex-col bg-N-50">
            <ChatHeader />
            <div className="flex-grow h-[calc(100vh-236px)]">
                <ChatContentComponent />
            </div>
            {showChatActions && <ChatActions />}
        </div>
    );
};
