import { DocumentPersistenceType, SettingsModelType, SettingsOptionType } from '../__generated__/graphql';
import { HEADER_LOGO_URL, KAYA_CDN_URL } from './EnvironmentConstants';

export const KAYA_ICON_BASE_URL = `${KAYA_CDN_URL}/icons/bernie-bot`;

export const IMG_KAYA_LOGO_WHITE = HEADER_LOGO_URL;
export const IMG_KAYA_BOT_GIF = `${KAYA_ICON_BASE_URL}-chatbot-icon-v2.gif`;
export const IMG_KAYA_BOT_PNG = `${KAYA_ICON_BASE_URL}-chatbot-icon-v2.png`;
export const IMG_USER_PNG = `${KAYA_CDN_URL}/icons/user-circle.svg`;
export const IMG_KAYA_POPUP_BOT = `${KAYA_ICON_BASE_URL}-chatbot-anime.gif`;
export const IMG_KAYA_POPUP_CLOSE = `${KAYA_ICON_BASE_URL}-close.png`;
export const IMG_KAYA_MIC_ON_GIF = `${KAYA_ICON_BASE_URL}-mic-on.gif`;
export const IMG_KAYA_MIC_DEFAULT_GIF = `${KAYA_ICON_BASE_URL}-mic-default.gif`;
export const IMG_KAYA_HOW_GIF = `${KAYA_ICON_BASE_URL}-how.gif`;
export const IMG_KAYA_ADD_GIF = `${KAYA_ICON_BASE_URL}-add.gif`;
export const IMG_KAYA_BUTTON_SEND_DISABLED = `${KAYA_ICON_BASE_URL}-send-icon-disabled.png`;
export const IMG_KAYA_BUTTON_SEND = `${KAYA_ICON_BASE_URL}-send-icon.png`;

export const CSS_SCROLL =
    'overflow-y-scroll overflow-x-hidden scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch';
export const CSS_INPUT =
    'leading-[15px] resize-none focus:outline-none focus:placeholder-N-400 text-N-600 placeholder-N-600 bg-white rounded-[5px] p-2 mx-1 border-[2px] border-N-300 focus:border-[2px] focus:ring-B-500 focus:border-B-500';
export const CSS_LIST = `flex flex-col h-[calc(100vh-150px)] ${CSS_SCROLL} sm:pb-[32px]`;

export const MAX_TEXT_LENGTH = 10000;
export const MOBILE_SCREEN_QUERY = `(max-width:640px)`;
export const MOBILE_SCREEN_SIZE = 640;

export const FEEDBACK_QUESTIONS = [
    { text: "👌 How satisfied were you with Bernie's technical assistance?", type: 'RATING' },
    {
        text: '🤝 How useful was Bernie for employee engagement tasks?',
        type: 'RATING',
    },
    { text: '🔄 How likely are you to continue using Bernie in your daily tasks?', type: 'RATING' },
    { text: '🐞 Have you found any bugs in Bernie?', type: 'FILE' },
    { text: '📝 Enter your feedback here', type: 'TEXT' },
];

export const MODEL_TYPES = [
    {
        model: SettingsModelType.OpenAi,
        name: 'GPT-4',
        imageURL: `${KAYA_ICON_BASE_URL}-${SettingsModelType.OpenAi}.png`,
    },
    {
        model: SettingsModelType.OpenAiOmni,
        name: 'GPT-4o',
        imageURL: `${KAYA_ICON_BASE_URL}-${SettingsModelType.OpenAiOmni}.png`,
    },
    {
        model: SettingsModelType.GoogleAi,
        name: 'Gemini',
        imageURL: `${KAYA_ICON_BASE_URL}-${SettingsModelType.GoogleAi}.png`,
    },
    {
        model: SettingsModelType.Claude,
        name: 'Claude',
        imageURL: `${KAYA_ICON_BASE_URL}-${SettingsModelType.Claude}.png`,
    },
    {
        model: SettingsModelType.Mistral,
        name: 'Mistral',
        imageURL: `${KAYA_ICON_BASE_URL}-${SettingsModelType.Mistral}.png`,
    },
];

export const DEFAULT_MODELS = MODEL_TYPES.map(model => model.model).join(',');

export const SETTING_TYPES = [
    {
        type: SettingsOptionType.DynamicPrompts,
        name: 'Dynamic Prompts',
        key: 'dynamicPrompts',
        description: 'Enhance your query with dynamic prompts',
    },
    {
        type: SettingsOptionType.WebSearch,
        name: 'Web Search',
        key: 'webSearch',
        description: 'Enable web search for real-time information',
    },
    {
        type: SettingsOptionType.NoLlm,
        name: 'No-LLM',
        key: 'noLLM',
        description: 'Disable LLM for faster responses',
    },
];

export const DOCUMENT_PERSISTENCE_TYPES = [
    {
        type: DocumentPersistenceType.Permanent,
        name: 'Save Permanently to current KPL',
    },
    {
        type: DocumentPersistenceType.Temporary,
        name: 'Save Temporarily to current Chat',
    },
];

export const SYSTEM_MODEL_TEMP_ID = 'sys_model_temp';
export const FEATURE_MODEL_TEMP_ID = 'feature_model_temp';
export const USER_TEMP_ID = 'user_tmp';
export const SYSTEM_TEMP_ID = 'sys_temp';
export const TOTAL_TEMP_MESSAGE_TIME = 120000; // 2 minutes
