import { Tag, TextField, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { CSS_LIST } from '../../../constants';
import { useChatHistory } from '../../../hooks';
import { uniqueId, CN } from '../../../utils';
import { MobileMenuButton } from '../buttons';
import { ChatHistoryCard } from '../chat-history-card';

export const ChatHistory = () => {
    const {
        count,
        groupedConversations,
        value,
        loading,
        conversationId,
        handleMobileMenuIconClick,
        isCollapsed,
        isMobile,
        setValue,
        fetchConversations,
        onConversationUpdate,
        handleConversationSelection,
    } = useChatHistory();

    return (
        <div>
            <div className="flex justify-between items-center border-b border-b-N-200">
                <div className="chat_history_title py-[20px] sm:py-4 px-6 sm:px-3 flex gap-x-[10px] items-center justify-center">
                    <p className="text-lg sm:text-lg font-600 text-N-900">Chat History</p>{' '}
                    <Tooltip id={uniqueId()} title={'Total Chats'} tooltipType="minimal" variant="dark">
                        <Tag label={count} appearance="neutral" size={isMobile ? 'xs' : 'small'} />
                    </Tooltip>
                    <Tooltip id={uniqueId()} title={'Reload Chats'} tooltipType="minimal" variant="dark">
                        <i
                            onClick={() => fetchConversations()}
                            className={CN(
                                'ri-refresh-line text-sm font-500 text-N-900 cursor-pointer hover:text-[#1D5BD7] hover:font-bold',
                                {
                                    'animate-spin': loading,
                                }
                            )}
                        />{' '}
                    </Tooltip>
                </div>
                <MobileMenuButton />
            </div>
            <div className="chat_history_input p-3 border-b border-b-N-200">
                <TextField
                    value={value}
                    onChange={(event: any) => setValue(event.currentTarget.value)}
                    view="default"
                    wrapperClassName={`w-full ${isCollapsed ? 'invisible' : 'visible'}`}
                    placeholder="Search chat history"
                    iconBefore="ri-search-line"
                />
            </div>
            <div className={CSS_LIST}>
                {groupedConversations.map(({ date, conversations }, index) => (
                    <div className="my-3" key={index}>
                        <div className="flex gap-1 justify-start pl-4 text-center text-sm text-N-800 py-2">
                            <span className="ml-1">{date}</span>
                        </div>
                        {conversations.map(conversation => (
                            <ChatHistoryCard
                                key={conversation.id}
                                id={conversation.id}
                                selected={Boolean(conversationId === conversation.id)}
                                onClick={() => {
                                    handleConversationSelection(conversation);
                                    handleMobileMenuIconClick();
                                }}
                                onUpdate={(id: string, title: string) => {
                                    onConversationUpdate({ ...conversation, id, title });
                                }}
                                chatContent={String(conversation.title)}
                                icon={String(conversation.feature.icon + ' text-sm text-600')}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
