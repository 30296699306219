import { Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { SideBarButtonSelectionTypes, useChatContext, useSideBarContext } from '../../../../contexts';
import { uniqueId } from '../../../../utils';
import { SideBarButton } from '../../buttons';

export const SidebarPrimaryBtnSection = () => {
    const { handleNewChat } = useChatContext();
    const { handleMobileMenuIconClick } = useSideBarContext();

    return (
        <div className="sidebar_primary_section flex flex-col gap-y-6 items-center justify-center">
            <Tooltip
                id={uniqueId()}
                place="left"
                variant="dark"
                tooltipType="minimal"
                title={'New Chat'}
                className="!pb-1"
            >
                <div
                    onClick={() => {
                        handleNewChat();
                        handleMobileMenuIconClick();
                    }}
                    className="group new-chat-btn bg-B-600 rounded-[4px] w-10 h-10 sm:w-8 sm:h-8 flex justify-center items-center cursor-pointer hover:bg-white hover:border hover:border-B-600"
                >
                    <i className="ri-add-fill text-h6 text-white ml-[1.5px] mt-[0.75px] group-hover:text-B-600 sm:text-h6" />
                </div>
            </Tooltip>
            <div className="flex flex-col gap-y-6 border-t border-N-300 pt-6">
                <SideBarButton
                    toolTipLabel="Chat History"
                    type="primary"
                    icon="ri-chat-history-line"
                    buttonSelectionType={SideBarButtonSelectionTypes.ChatHistorySelection}
                />
                <SideBarButton
                    toolTipLabel="Explore KPLs"
                    type="primary"
                    icon="ri-stack-fill"
                    buttonSelectionType={SideBarButtonSelectionTypes.KplListSelection}
                />
            </div>
        </div>
    );
};
