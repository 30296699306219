import React, { useEffect, useState } from 'react';
import { IMG_KAYA_BOT_GIF } from '../../constants';
import { ChatContentContainer, ExtendedSidebar, Sidebar } from '../common';
import { FullScreenLayout } from '../layout';
import { useMobile } from '../../hooks';
import { useSideBarContext } from '../../contexts';

type ChatContentProps = {
    showHeader?: boolean;
    onExternalLinkClick?: () => void;
};

const ChatContent: React.FC<ChatContentProps> = ({ showHeader = false, onExternalLinkClick = () => {} }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { showMobileSideBar } = useSideBarContext();
    const isMobile = useMobile();

    useEffect(() => {
        const hasSplashScreenBeenShown = sessionStorage.getItem('splashScreenShown');

        if (hasSplashScreenBeenShown) {
            setIsLoading(false);
        } else {
            setTimeout(() => {
                setIsLoading(false);
                sessionStorage.setItem('splashScreenShown', 'true');
            }, 2000);
        }
    }, []);

    if (isLoading) {
        return (
            <div className="animate-jump-in animate-once animate-duration-1000 animate-delay-100 flex justify-center items-center h-full bg-gradient-to-l from-[#2C3E95] to-[#1F2937]">
                <img alt="Kaya Chatbot GIF" src={IMG_KAYA_BOT_GIF} className="rounded-full" />
            </div>
        );
    }

    // Render a full-screen layout with a sidebar, an extended sidebar, and a chat content container
    return (
        <FullScreenLayout>
            {isMobile ? (
                showMobileSideBar && (
                    <>
                        <Sidebar />
                        <ExtendedSidebar />
                    </>
                )
            ) : (
                <>
                    <Sidebar />
                    <ExtendedSidebar />
                </>
            )}
            <ChatContentContainer />
        </FullScreenLayout>
    );
};

export { ChatContent };
